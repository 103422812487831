import ReactGA from 'react-ga4';
import _ from 'lodash';
import {GET_BUSY} from "./Actions/getBusy";
import {GET_REST} from "./Actions/getRest";
import {RESET_CURRENT_TENANT_WINDOW} from "./Actions/resetCurrentTenantWindow";
import {RESET_CURRENT_PROVIDER_WINDOW} from "./Actions/resetCurrentProviderWindow";
import {RESET_CURRENT_WINDOW} from "./Actions/resetCurrentWindow";
import {RESET_CURRENT_PURCHASER_WINDOW} from "./Actions/resetCurrentPurchaserWindow";
import {SET_ACCESS_TOKEN} from "./Actions/setAccessToken";
import {SET_TENANT_ACCESS_TOKEN} from "./Actions/setTeanantAccessToken";
import {SET_CONNECTIVITY_STATUS} from "./Actions/setConnectivityStatus";
import {LOAD_CACHE_ENTRY} from "./Actions/loadCacheEntry";
import {ADD_CACHE_ENTRY} from "./Actions/addCacheEntry";

const excludedActionTypes = [
    GET_BUSY,
    GET_REST,
    RESET_CURRENT_TENANT_WINDOW,
    RESET_CURRENT_PROVIDER_WINDOW,
    RESET_CURRENT_PURCHASER_WINDOW,
    RESET_CURRENT_WINDOW,
    SET_ACCESS_TOKEN,
    SET_TENANT_ACCESS_TOKEN,
    SET_CONNECTIVITY_STATUS,
    LOAD_CACHE_ENTRY,
    ADD_CACHE_ENTRY
];

export const analyticsMiddleware = store => next => action => {

    if (!store.getState().privacyPolicyAccepted) {
        return next(action);
    }

    if (!_.includes(excludedActionTypes, action.type)) {
        const tenantId = action.tenantId;

        const payload = {
            category: 'actions',
            action: action.type,
            label: tenantId
        };

        if (process.env.REACT_APP_GA_DEBUG) {
            console.log('GA', payload);
        } else {
            ReactGA.event(payload);
        }
    }

    return next(action);
}