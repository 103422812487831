import React from 'react';
import history from '../sharedHistory';

const Link = React.forwardRef((props, ref) => {
    const onClick = e => {
        history.push(props.href);
        e.preventDefault();
        if (props.onClick) {
            props.onClick(e);
        }
    };
    return <a {...props} ref={ref} onClick={onClick} href={props.href}>{props.children}</a>
});

export default Link;