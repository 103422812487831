import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import RawLoginForm from '../ViewComponents/LoginForm';
import {login} from "../State/Actions/login";

export function LoginForm() {
    const {loginForm} = useSelector(o => o.currentWindow);
    const dispatch = useDispatch();

    return !loginForm
        ? <React.Fragment/>
        : <RawLoginForm {...loginForm} onSubmit={data => dispatch(login(data.email, data.password))}/>
}

export default LoginForm;
