import {applyMiddleware, compose, createStore as reduxCreateStore} from "redux";
import createSagaMiddleware from 'redux-saga';
import rootReducer, {getInitialState} from "./Reducers/rootReducer";
import initSagas from './initSagas';
import {analyticsMiddleware} from "./analyticsMiddleware";

const createStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [analyticsMiddleware, sagaMiddleware];
    const composables =  [applyMiddleware(...middlewares)];
    const enhancer = compose(...composables);

    const initialState = getInitialState();

    let result = reduxCreateStore(rootReducer, initialState, enhancer);

    initSagas(sagaMiddleware);

    return result;
};

export default createStore;