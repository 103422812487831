import React from 'react';
import WizardFooter from "./WizardFooter";
import {TextField, Typography} from "@material-ui/core";

import ErrorsPanel from "./ErrorsPanel";
import {PasswordStrengthValidator, usePassword} from "./PasswordStrengthValidator";


export function PasswordForm({errors, checking, onSubmit, onPrevious}) {
    const {
        password,
        setPassword,
        passwordRepeat,
        setPasswordRepeat,
        samePasswords,
        onFormSubmit
    } = usePassword(onSubmit);

    return <form onSubmit={onFormSubmit}>
        <ErrorsPanel errors={errors}/>
        <TextField
            autoFocus
            label="Wpisz hasło"
            type="password"
            value={password}
            fullWidth
            onChange={e => setPassword(e.target.value)}/>
        <TextField
            label="Wpisz hasło jeszcze raz"
            type="password"
            error={!!password && !samePasswords}
            value={passwordRepeat}
            fullWidth
            onChange={e => setPasswordRepeat(e.target.value)}/>
        {!!password && !samePasswords && <Typography variant="caption" color="secondary">Hasła się różnią</Typography>}
        <PasswordStrengthValidator password={password}/>

        <WizardFooter checking={checking} onPrevious={onPrevious} />
    </form>
}

export default PasswordForm;