import React, {useState} from "react";
import {Check, Clear} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    unstyledList: {
        listStyle: 'none',
        paddingLeft: 0
    },
    clearCheck: {
        verticalAlign: 'middle'
    }
}));

function ClearCheck({check}) {
    const classes = useStyles();
    return check
        ? <Check color="primary" fontSize="small" className={classes.clearCheck}/>
        : <Clear color="secondary" fontSize="small" className={classes.clearCheck}/>
}

export function validatePassword(password) {
    const isLongEnough = password.length >= 8;
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecial = /[!@#$%^&*]/.test(password);

    return {
        isLongEnough,
        hasLowerCase,
        hasUpperCase,
        hasDigit,
        hasSpecial,
        isStrongEnough: isLongEnough && hasLowerCase && hasUpperCase && hasDigit && hasSpecial
    }
}

export function usePassword(onSubmit) {
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const samePasswords = password === passwordRepeat;
    const {isStrongEnough} = validatePassword(password)

    const canBeSaved = !!password && isStrongEnough && samePasswords;

    const onFormSubmit = e => {
        e.preventDefault();

        if (!canBeSaved) {
            return;
        }

        onSubmit(password);
    };

    return {
        password,
        setPassword,
        passwordRepeat,
        setPasswordRepeat,
        samePasswords,
        canBeSaved,
        onFormSubmit
    }
}

export function PasswordStrengthValidator({password}) {
    const classes = useStyles();
    const {
        isLongEnough,
        hasLowerCase,
        hasUpperCase,
        hasDigit,
        hasSpecial
    } = validatePassword(password);

    return <ul className={classes.unstyledList}>
        <li><ClearCheck check={isLongEnough}/> <span>Hasło jest wystarczająco długie (8 znaków)</span></li>
        <li><ClearCheck check={hasLowerCase}/> Hasło zawiera małe litery.</li>
        <li><ClearCheck check={hasUpperCase}/> Hasło zawiera duże litery.</li>
        <li><ClearCheck check={hasDigit}/> Hasło zawiera cyfry.</li>
        <li><ClearCheck check={hasSpecial}/> Hasło zawiera znaki specjalne(!@#$%^&*).</li>
    </ul>
}