import {call, delay, put, takeLatest, all, select} from 'redux-saga/effects';
import {LOGIN} from '../Actions/login';
import {LOGOUT} from '../Actions/logout';
import {setAuthStatus, LOGGED_IN, NOT_LOGGED_IN, LOGGED_OUT, CHECKING} from '../Actions/setAuthStatus';
import {setAccessToken} from "../Actions/setAccessToken";
import {inviteUser} from "../Actions/inviteUser";
import axios from "../../exios";
import {OPEN_LOGIN_FORM, openLoginForm} from "../Actions/openLoginForm";
import moment from "moment";
import {clearAccessTokens} from "../Actions/clearAccessTokens";
import {OPEN_REGISTER_FORM} from "../Actions/openRegisterForm";
import {OPEN_RESET_PASSWORD_FORM} from "../Actions/openResetPasswordForm";
import {resetCurrentWindow} from "../Actions/resetCurrentWindow";
import {CHECK_EMAIL} from "../Actions/checkEmail";
import {REGISTER} from "../Actions/register";
import {RESET_PASSWORD} from "../Actions/resetPassword";
import {ACCEPT_PRIVACY_POLICY, acceptPrivacyPolicy} from "../Actions/acceptPrivacyPolicy";

const AUTH_EXPIRATION = 'AUTH_EXPIRATION';
const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';

const PRIVACY_POLICY_ACCEPTED = 'PRIVACY_POLICY_ACCEPTED';
const CURRENT_PRIVACY_POLICY_VERSION = 3;

const THIRTY_SECONDS = moment.duration(30, 'seconds');
const getExpiration = (result) => (moment().add(moment.duration(result.expiresIn, 'seconds')).toJSON());
const expired = (expiration) => ((moment().subtract(THIRTY_SECONDS).isAfter(moment(expiration))));

const requestConfigTemplate = {
    validateStatus: status => (status >= 200 && status < 300) || (status >= 400 && status < 500)
}

const loginUrl = process.env.REACT_APP_AUTH_URL + '/oauth/token';

function* login({email, password}) {
    try {
        yield put(resetCurrentWindow({loginForm: {checking: true}}));

        const audience = 'https://tenants.skupomat.pl';
        const {data: result, status} = yield call(axios.post, loginUrl, {email, password, audience}, requestConfigTemplate);

        if(status !== 200) {
            yield put(resetCurrentWindow({loginForm: {errors: result.errors}}));
            return;
        }

        localStorage.setItem(AUTH_EXPIRATION, getExpiration(result));
        localStorage.setItem(AUTH_REFRESH_TOKEN, result.refreshToken);
        yield put(setAccessToken(audience, result.accessToken));

        yield put(setAuthStatus(LOGGED_IN));
        yield put(inviteUser());
    } catch(e) {
        yield put(resetCurrentWindow({loginForm: {errors: [{ErrorMessage: 'Coś poszło nie tak, spróbuj jeszcze raz'}]}}));
    }
}

const revokeTokenUrl = process.env.REACT_APP_AUTH_URL + '/oauth/revoke';

function* logout() {
    const refreshToken = yield call([localStorage, localStorage.getItem], AUTH_REFRESH_TOKEN);
    if (refreshToken) {
        try {
            yield call(axios.post, revokeTokenUrl, {refreshToken}, requestConfigTemplate)
        } catch {
            // Nothing
        }
    }

    yield call([localStorage, localStorage.removeItem], AUTH_EXPIRATION);
    yield call([localStorage, localStorage.removeItem], AUTH_REFRESH_TOKEN);
    yield put(clearAccessTokens());
    yield put(setAuthStatus(LOGGED_OUT));
    yield put(openLoginForm());
    yield delay(100);
    yield put(resetCurrentWindow({loginForm: {message: "Wylogowano"}}))
}

function* getCurrentAccessToken(audience) {
    if (!!audience) {
        const accessToken = yield select(o => o.accessTokens[audience]);
        if (accessToken && !expired(accessToken.expiration)) {
            return accessToken;
        }
    }

    const refreshToken = yield call([localStorage, localStorage.getItem], AUTH_REFRESH_TOKEN);
    if (!refreshToken) {
        yield logout();
        return null;
    }

    const request = {
        token: refreshToken,
        audience
    };

    const refreshUrl = process.env.REACT_APP_AUTH_URL + '/oauth/refresh-token';
    const {data: result, status} = yield call(axios.post, refreshUrl, request, requestConfigTemplate)

    if (status === 403) {
        // To skip revocation
        yield call([localStorage, localStorage.removeItem], AUTH_REFRESH_TOKEN);
        yield logout();
        return null;
    }

    if (!!audience) {
        yield put(setAccessToken(audience, result.accessToken));
        localStorage.setItem(AUTH_EXPIRATION, getExpiration(result));
    }

    if(result.refreshToken) {
        localStorage.setItem(AUTH_REFRESH_TOKEN, result.refreshToken);
    }

    return result.accessToken;
}

export function* determineAuthState() {
    const privacyPolicyAccepted = localStorage.getItem(PRIVACY_POLICY_ACCEPTED);
    if (privacyPolicyAccepted >= CURRENT_PRIVACY_POLICY_VERSION) {
        yield put(acceptPrivacyPolicy());
    }

    const expiration = localStorage.getItem(AUTH_EXPIRATION);
    if (!expiration) {
        yield put(setAuthStatus(NOT_LOGGED_IN));
        return;
    }

    if (!expired(expiration)) {
        yield put(setAuthStatus(LOGGED_IN));
        return;
    }
    try {
        yield put(setAuthStatus(CHECKING));
        const token = yield getCurrentAccessToken('https://tenants.skupomat.pl');
        if (token) {
            yield put(setAuthStatus(LOGGED_IN));
        } else {
            yield put(setAuthStatus(NOT_LOGGED_IN));
        }
    } catch (e) {
        console.error('error while checking token', e);
        yield call([localStorage, localStorage.removeItem], AUTH_EXPIRATION);
        yield call([localStorage, localStorage.removeItem], AUTH_REFRESH_TOKEN);
        yield put(setAuthStatus(NOT_LOGGED_IN));
    }
}

function* handleOpenRegisterForm({email}) {
    yield put(resetCurrentWindow({registerForm: {step: 0, email}}))
}

function* handleCheckEmail({email}) {
    const checkUrl = `${process.env.REACT_APP_AUTH_URL}/users`;
    yield put(resetCurrentWindow({registerForm: {checking: true, step: 0}}));
    try {
        const config = {
            ...requestConfigTemplate,
            params: {
                email
            }
        }
        const {status} = yield call(axios.head, checkUrl, config);

        if (status === 200) {
            yield put(resetCurrentWindow({registerForm: {step: 0, exists: true}}));
        } else {
            yield put(resetCurrentWindow({registerForm: {step: 1, email}}));
        }
    }
    catch (e)
    {
        const errors = [{
            ErrorMessage: "Coś poszło nie tak. Spróbuj ponownie."
        }]
        yield put(resetCurrentWindow({registerForm: {step: 0, errors}}));
    }
}

function* handleRegister({email, password}) {
    const registerUrl = `${process.env.REACT_APP_AUTH_URL}/users`;
    yield put(resetCurrentWindow({registerForm: {checking: true, step: 1}}));

    try {
        const request = {
            email,
            password
        };
        const {data, status} = yield call(axios.post, registerUrl, request, requestConfigTemplate);

        if (status === 200) {
            const audience = 'https://tenants.skupomat.pl';
            const {data: result, status} = yield call(axios.post, loginUrl, {email, password, audience}, requestConfigTemplate);

            if(status !== 200) {
                yield put(resetCurrentWindow({registerForm: { step: 1, email, errors: result.errors}}));
                return;
            }

            localStorage.setItem(AUTH_EXPIRATION, getExpiration(result));
            localStorage.setItem(AUTH_REFRESH_TOKEN, result.refreshToken);
            yield put(setAccessToken(audience, result.accessToken));

            yield put(setAuthStatus(LOGGED_IN));
            yield put(inviteUser());
        } else {
            yield put(resetCurrentWindow({registerForm: {step: 1, email, errors: data.errors}}));
        }
    }
    catch (e)
    {
        const errors = [{
            ErrorMessage: "Coś poszło nie tak. Spróbuj ponownie."
        }]
        yield put(resetCurrentWindow({registerForm: {step: 1, email, errors}}));
    }
}

function* handleOpenLoginForm() {
    yield put(resetCurrentWindow({loginForm: {}}));
}

function* handleOpenResetPasswordForm(){
    yield put(resetCurrentWindow({resetPasswordForm: {}}));
}

function* handleResetPassword({email}) {
    const resetUrl = `${process.env.REACT_APP_AUTH_URL}/oauth/password-reset`;
    yield put(resetCurrentWindow({resetPassword: {checking: true}}));
    try {
        const {status, data} = yield call(axios.post, resetUrl, {email}, requestConfigTemplate);

        if (status === 200) {
            yield put(openLoginForm());
            yield delay(100);
            yield put(resetCurrentWindow({loginForm: {message: "Wysłaliśmy wiadomość z linkiem do ustawienia nowego hasła."}}))
        } else {
            yield put(resetCurrentWindow({registerForm:{ errors: data.errors}}));
        }
    }
    catch (e)
    {
        const errors = [{
            ErrorMessage: "Coś poszło nie tak. Spróbuj ponownie."
        }]
        yield put(resetCurrentWindow({registerForm: {step: 0, errors}}));
    }
}

// TODO: watch local storage

export function* getAxiosConfig(audience) {
    const accessToken = yield getCurrentAccessToken(audience);
    return {
        headers: {'Authorization': 'Bearer ' + accessToken}
    };
}

function* handleAcceptPrivacyPolicy() {
    yield call([localStorage, localStorage.setItem], PRIVACY_POLICY_ACCEPTED, JSON.stringify(CURRENT_PRIVACY_POLICY_VERSION));
}

export function* authSaga() {
    yield all([
        takeLatest(LOGIN, login),
        takeLatest(LOGOUT, logout),
        takeLatest(OPEN_REGISTER_FORM, handleOpenRegisterForm),
        takeLatest(CHECK_EMAIL, handleCheckEmail),
        takeLatest(REGISTER, handleRegister),
        takeLatest(OPEN_LOGIN_FORM, handleOpenLoginForm),
        takeLatest(OPEN_RESET_PASSWORD_FORM, handleOpenResetPasswordForm),
        takeLatest(RESET_PASSWORD, handleResetPassword),
        takeLatest(ACCEPT_PRIVACY_POLICY, handleAcceptPrivacyPolicy)
    ]);
}
