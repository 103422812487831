import {all, call, put, select, takeLatest} from "redux-saga/effects";
import axios from "../../exios";
import _ from 'lodash';
import {OPEN_ACCOUNT_SETTINGS} from "../Actions/openAccountSettings";
import {resetCurrentTenantWindow} from "../Actions/resetCurrentTenantWindow";
import {CHANGE_PASSWORD} from "../Actions/changePassword";
import {getAxiosConfig} from "./authSaga";
import {busyCall} from "./businessSaga";
import {getCurrentTenantId} from "../stateUtils";
import {OPEN_USERS_LIST, openUsersList} from "../Actions/openUsersList";
import {OPEN_ADD_USER_FORM} from "../Actions/openAddUserForm";
import {ADD_USER} from "../Actions/addUser";
import {ADD_USER_WITH_PASSWORD} from "../Actions/addUserWithPassword";
import {OPEN_EDIT_USER_FORM} from "../Actions/openEditUserForm";
import {UPDATE_USER_PERMISSIONS} from "../Actions/updateUserPermissions";

function getConfig() {
    return getAxiosConfig('https://tenants.skupomat.pl');
}

function* handleOpenAccountSettings({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, {userAccountSettings:{}}));
}

function* handleChangePassword({oldPassword, newPassword}) {
    const config = yield getConfig();
    config.validateStatus = status => (status >= 200 && status < 300) || status === 422;

    const initWindowState = yield select(o => o.currentWindow.userAccountSettings);
    const tenantId = yield select(getCurrentTenantId);

    yield put(resetCurrentTenantWindow(tenantId, {userAccountSettings:{...initWindowState, changingPassword: true}}));

    const url = `${process.env.REACT_APP_AUTH_URL}/users/password`;
    const {data, status} = yield busyCall(axios.put, url, {oldPassword, newPassword}, config);

    if (status === 422) {
        yield put(resetCurrentTenantWindow(tenantId, {userAccountSettings:{...initWindowState, passwordChangeErrors: data.errors}}));
    } else {
        yield put(resetCurrentTenantWindow(tenantId, {userAccountSettings:{...initWindowState, snack: "Hasło zostało zmienione"}}));
    }
}

function* loadUserEmail(userId, tenantId, config) {
    const url = `${process.env.REACT_APP_AUTH_URL}/users/${userId}`;
    const {data} = yield busyCall(axios.get, url, config);

    const users = yield select(o => o.currentWindow?.usersList?.data);

    if (!users) return;

    const userIndex = _.findIndex(users, u => u.userId === userId);
    const user = users[userIndex];
    const newUsers = [...users];

    newUsers[userIndex] = {
        ...user,
        loading: false,
        email: data.email
    }
    yield put(resetCurrentTenantWindow(tenantId, {usersList: {data: newUsers}}));
}

function* handleOpenUsersList({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, {usersList: {loading: true}}));

    const config = yield getConfig();
    const usersUrl = `${process.env.REACT_APP_TENANT_URL}/tenants/${tenantId}/users`;
    const {data: users} = yield busyCall(axios.get, usersUrl, config);

    const loadingUsers = users.map(o => ({
        ...o,
        loading: true
    }));

    yield put(resetCurrentTenantWindow(tenantId, {usersList: {data: loadingUsers}}));
    yield all(loadingUsers.map(u => loadUserEmail(u.userId, tenantId, config)));
}

export function* handleOpenAddUserForm({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, {addUserForm: {}}));
}

function* handleAddUser({tenantId, data}) {
    yield put(resetCurrentTenantWindow(tenantId, {addUserForm: {saving: true}}));
    const checkConfig = yield getConfig();
    checkConfig.validateStatus = status => status === 200 || status === 404;
    checkConfig.params = {
        email: data.email
    };

    const usersUrl = `${process.env.REACT_APP_AUTH_URL}/users`;
    const {data: userDetails, status} = yield busyCall(axios.get, usersUrl, checkConfig);

    if (status === 200) {
        const createUserUrl = `${process.env.REACT_APP_TENANT_URL}/tenants/${tenantId}/users`;
        const config = yield getConfig();
        const createUserPayload = {
            userId: userDetails.id
        };
        yield busyCall(axios.post, createUserUrl, createUserPayload, config);

        yield put(openUsersList(tenantId));
    } else {
        yield put(resetCurrentTenantWindow(tenantId, {addUserWithPasswordForm: {email: data.email}}))
    }
}

function* handleAddUserWithPassword({tenantId, data}) {
    const config = yield getConfig();
    const registerUrl = `${process.env.REACT_APP_AUTH_URL}/users`;
    const {data: userId} = yield call(axios.post, registerUrl, data, config);

    const createUserPayload = {
        userId
    };

    const createUserUrl = `${process.env.REACT_APP_TENANT_URL}/tenants/${tenantId}/users`;
    yield busyCall(axios.post, createUserUrl, createUserPayload, config);

    yield put(openUsersList(tenantId));
}

function* handleOpenEditUserForm({tenantId, userId}) {
    const config = yield getConfig();
    console.log('Load user permissions', tenantId, userId);
    const [{data: permissions}, {data:userData}] = yield all([
        busyCall(axios.get, `${process.env.REACT_APP_TENANT_URL}/tenants/${tenantId}/users/${userId}/permissions`, config),
        busyCall(axios.get, `${process.env.REACT_APP_TENANT_URL}/tenants/${tenantId}/users/${userId}`, config)
    ]);
    console.log('Permissions loaded', permissions, userData);

    yield put(resetCurrentTenantWindow(tenantId, {editUserForm: {userId, permissions, userData}}));
}

function* handleUpdateUserPermissions({tenantId, userId, permissions}) {
    const oldEditUserForm = yield select(o => o.currentWindow.editUserForm);
    yield put(resetCurrentTenantWindow(tenantId, {editUserForm: {...oldEditUserForm, saving: true}}));

    const config = yield getConfig();
    const url = `${process.env.REACT_APP_TENANT_URL}/tenants/${tenantId}/users/${userId}/permissions`;
    yield busyCall(axios.put, url, permissions, config);

    yield put(openUsersList(tenantId));
}

export function* usersSaga(){
    yield all([
        takeLatest(OPEN_ACCOUNT_SETTINGS, handleOpenAccountSettings),
        takeLatest(CHANGE_PASSWORD, handleChangePassword),
        takeLatest(OPEN_USERS_LIST, handleOpenUsersList),
        takeLatest(OPEN_ADD_USER_FORM, handleOpenAddUserForm),
        takeLatest(ADD_USER, handleAddUser),
        takeLatest(ADD_USER_WITH_PASSWORD, handleAddUserWithPassword),
        takeLatest(OPEN_EDIT_USER_FORM, handleOpenEditUserForm),
        takeLatest(UPDATE_USER_PERMISSIONS, handleUpdateUserPermissions)
    ]);
}