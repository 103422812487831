import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import RawForm from '../ViewComponents/ResetPasswordForm';
import {resetPassword} from "../State/Actions/resetPassword";

export function ResetPasswordForm(){
    const {resetPasswordForm} = useSelector(o => o.currentWindow);
    const dispatch = useDispatch();

    return !resetPasswordForm
        ? <React.Fragment/>
        : <RawForm {...resetPasswordForm} onSubmit={email => dispatch(resetPassword(email))}/>;
}

export default ResetPasswordForm;