import React from 'react';
import ReactDOM from 'react-dom';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/pl';
import numeral from 'numeral';
import 'numeral/locales/pl';
import ReactGA from 'react-ga4';
import './index.css';
import App from './ConnectedComponents/App';
import {Provider} from "react-redux";
import createStore from './State/createStore';
import * as serviceWorker from './serviceWorker';


moment.locale('pl');
numeral.locale('pl');

ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const store = createStore();
ReactDOM.render(
    <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'pl'}>
            <App />
        </MuiPickersUtilsProvider>
    </Provider>,
    document.getElementById('root'));

console.info('Version', process.env.REACT_APP_VERSION);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
