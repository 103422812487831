import {all, put, takeLatest} from "redux-saga/effects";
import {OPEN_ADD_PAYMENT_FORM} from "../Actions/openAddPaymentForm";
import {resetCurrentTenantWindow} from "../Actions/resetCurrentTenantWindow";
import {SET_SUBSCRIPTION_ACTIVE_TO} from "../Actions/setSubscriptionExpirationDate";
import {getAxiosConfig as getRootAxiosConfig} from "./authSaga";
import axios from "../../exios";
import {busyCall} from "./businessSaga";
import {getUrl} from "./tenantsSaga";
import {resetCurrentWindow} from "../Actions/resetCurrentWindow";

function* handleAddPaymentForm({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, {addPaymentForm: {}}));
}

function* handleSetActiveTo({tenantId, date}) {
    const patchBody = {
        activeTo: date
    }
    const axiosConfig = yield getRootAxiosConfig('https://tenants.skupomat.pl');
    yield busyCall(axios.patch, getUrl(`/tenants/${tenantId}/active-to`), patchBody, axiosConfig);

    yield put(resetCurrentWindow({}));
    yield put(resetCurrentTenantWindow(tenantId, {addPaymentForm: {}}));
}

export function* paymentsSaga() {
    yield all([
        takeLatest(OPEN_ADD_PAYMENT_FORM, handleAddPaymentForm),
        takeLatest(SET_SUBSCRIPTION_ACTIVE_TO, handleSetActiveTo)
    ])
}