import React, {useEffect, useState} from 'react';
import {TextField} from "@material-ui/core";
import ErrorsPanel from "./ErrorsPanel";
import Link from './Link';
import WizardFooter from "./WizardFooter";
import {Alert} from "@material-ui/lab";

export function EmailForm({errors, initialEmail, checking, exists, onSubmit}) {
    const [email, setEmail] = useState('');

    useEffect(() => {
        setEmail(initialEmail || '');
    } , [initialEmail])

    const canSubmit = !!email;

    const onFormSubmit = e => {
        e.preventDefault();

        if (!canSubmit) return;

        onSubmit(email);
    };

    return <form onSubmit={onFormSubmit}>
        <ErrorsPanel errors={errors}/>
        {exists && <Alert color="info">
            Wyglada na to że masz już konto: <Link href="/login">Zaloguj się</Link>
        </Alert>}
        <TextField
            label="Podaj adres email"
            type="email"
            fullWidth
            value={email}
            disabled={checking}
            onChange={e => setEmail(e.target.value)}/>

        <WizardFooter checking={checking} showPrevious={false}/>
    </form>
}

export default EmailForm;