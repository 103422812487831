import {call, fork, takeLatest, put, select, all, cancel} from 'redux-saga/effects';
import {eventChannel} from 'redux-saga';
import {SET_AUTH_STATUS, LOGGED_IN, LOGGED_OUT, NOT_LOGGED_IN} from '../Actions/setAuthStatus';
import {OPEN_ADD_TENANT_FORM, openAddTenantForm} from '../Actions/openAddTenantForm';
import {OPEN_TENANTS_LIST, openTenantsList} from "../Actions/openTenantsList";
import {OPEN_TENANT_PREVIEW, openTenantPreview} from '../Actions/openTenantsPreview';
import {OPEN_EDIT_TENANT_FORM, openEditTenantForm} from "../Actions/openEditTenantForm";
import {OPEN_PRODUCT_LIST, openProductList} from "../Actions/openProductList";
import {OPEN_ADD_PRODUCT_FORM, openAddProductForm} from "../Actions/openAddProductForm";
import {OPEN_EDIT_PRODUCT_FORM, openEditProductForm} from "../Actions/openEditProductForm";
import {OPEN_ADD_PROVIDER_FORM, openAddProviderForm} from "../Actions/openAddProviderForm";
import {OPEN_PROVIDER_PREVIEW, openProviderPreview} from "../Actions/openProviderPreview";
import {OPEN_ADD_DEAL_FORM, openAddDealForm} from "../Actions/openAddDealForm";
import {OPEN_RR_INVOICES_LIST, openRrInvoicesList} from "../Actions/openRrInvoicesList";
import {INVITE_USER, inviteUser} from "../Actions/inviteUser";
import history from '../../sharedHistory';
import {tenantRoute, providerRoute, purchaserRoute} from "../../routes";
import {OPEN_PURCHASERS_LIST, openPurchasersList} from "../Actions/openPurchasersList";
import {OPEN_ADD_PURCHASER_FORM, openAddPurchaserForm} from "../Actions/openAddPurchaserForm";
import {OPEN_PURCHASER_PREVIEW, openPurchaserPreview} from "../Actions/openPurchaserPreview";
import {OPEN_ADD_INVOICE_FORM, openAddInvoiceForm} from "../Actions/openAddInvoiceForm";
import {OPEN_ADD_RR_INVOICE_FORM, openAddRrInvoiceForm} from "../Actions/openAddRrInvoiceForm";
import {OPEN_EDIT_PROVIDER_FORM, openEditProviderForm} from "../Actions/openEditProviderForm";
import {openEditPurchaserForm} from "../Actions/openEditPurchaserForm";
import {OPEN_PROVIDER_LIST, openProviderList} from "../Actions/openProviderList";
import {OPEN_DEALS_LIST, openDealsList} from "../Actions/openDealsList";
import {OPEN_INVOICE_PREVIEW, openInvoicePreview} from "../Actions/openInvoicePreview";
import {OPEN_EDIT_INVOICE_FORM, openEditInvoiceForm} from "../Actions/openEditInvoiceForm";
import {OPEN_RR_INVOICE_PREVIEW, openRrInvoicePreview} from "../Actions/openRrInvoicePreview";
import {OPEN_EDIT_RR_INVOICE_FORM, openEditRrInvoiceForm} from "../Actions/openEditRrInvoiceForm";
import {OPEN_RR_INVOICES_REPORT, openRrInvoicesReport} from "../Actions/openRrInvoicesReport";
import {OPEN_UNPAID_RR_INVOICES_SUMMARY, openUnpaidRrInvoicesSummary} from "../Actions/openUnpaidRrInvoicesSummary";
import {OPEN_INVOICES_REPORT, openInvoicesReport} from "../Actions/openInvoicesReport";
import {OPEN_REGISTER_FORM, openRegisterForm} from "../Actions/openRegisterForm";
import {OPEN_LOGIN_FORM, openLoginForm} from "../Actions/openLoginForm";
import {OPEN_RESET_PASSWORD_FORM, openResetPasswordForm} from "../Actions/openResetPasswordForm";
import {OPEN_ACCOUNT_SETTINGS, openAccountSettings} from "../Actions/openAccountSettings";
import {OPEN_USERS_LIST, openUsersList} from "../Actions/openUsersList";
import {OPEN_ADD_USER_FORM, openAddUserForm} from "../Actions/openAddUserForm";
import {OPEN_EDIT_USER_FORM, openEditUserForm} from "../Actions/openEditUserForm";
import {OPEN_ADD_PAYMENT_FORM, openAddPaymentForm} from "../Actions/openAddPaymentForm";
import {OPEN_CONTAINER_LIST, openContainerList} from "../Actions/openContainerList";
import {OPEN_ADD_CONTAINER_FORM, openAddContainerForm} from "../Actions/openAddContainerForm";
import {OPEN_EDIT_CONTAINER_FORM, openEditContainerForm} from "../Actions/openEditContainerForm";
import {OPEN_CONTAINER_SHARES_LIST, openContainerSharesList} from "../Actions/openContainerSharesList";
import {OPEN_ADD_CONTAINER_SHARE_FORM, openAddContainerShareForm} from "../Actions/openAddContainerShareForm";
import {OPEN_EDIT_CONTAINER_SHARE_FORM, openEditContainerShareForm} from "../Actions/openEditContainerShareForm";
import {OPEN_EDIT_DEAL_FORM, openEditDealForm} from "../Actions/openEditDealForm";
import {OPEN_UNPAID_INVOICES_SUMMARY, openUnpaidInvoicesSummary} from "../Actions/openUnpaidInvoicesSummary";
import {OPEN_TENANT_FEATURE_FLAGS_FORM, openTenantFeatureFlagsForm} from "../Actions/openTenantFeatureFlags";
import {OPEN_INVOICES_LIST, openInvoicesList} from "../Actions/openInvoicesList";
import {OPEN_PROVISION_LIST, openProvisionList} from "../Actions/openProvisionList";
import {OPEN_ADD_PROVISION_FORM, openAddProvisionForm} from "../Actions/openAddProvisionForm";
import {OPEN_EDIT_PROVISION_FORM, openEditProvisionForm} from "../Actions/openEditProvisionForm";

function* navigateTo(route) {
    if (history.location.pathname !== route) {
        yield call([history, history.push], route);
    }
}

function* handleOpenTenantForm() {
    yield navigateTo('/tenants/add');
}

function* handleOpenTenantList() {
    yield navigateTo('/tenants/');
}

function* handleOpenTenantPreview({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/'));
}

function* handleOpenEditTenantForm({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/edit'));
}

function* handleOpenTenantFeatureFlagsForm({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/feature-flags/'));
}

function* handleOpenProvidersList({tenantId}) {
   yield navigateTo(tenantRoute(tenantId, '/providers/'));
}

function* handleOpenAddProvidersForm({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/providers/add'));
}

function* handleOpenEditProviderForm({tenantId, providerId}) {
    yield navigateTo(providerRoute(tenantId, providerId, '/edit'));
}

function* handleOpenProviderPreview({tenantId, providerId}) {
    yield navigateTo(providerRoute(tenantId, providerId, '/'));
}

function* handleOpenDealsList({tenantId, providerId}) {
    yield navigateTo(providerRoute(tenantId, providerId, '/deals/'));
}

function* handleOpenAddDealForm({tenantId, providerId}) {
    yield navigateTo(providerRoute(tenantId, providerId, '/deals/add'));
}

function* handleEditDealForm({tenantId, providerId, dealPositionId}) {
    yield navigateTo(providerRoute(tenantId, providerId, `/deals/${dealPositionId}`));
}

function* handleOpenRrInvoicesList({tenantId, providerId}) {
    yield navigateTo(providerRoute(tenantId, providerId, '/invoices/'));
}

function* handleOpenAddRrInvoiceForm({tenantId, providerId, invoiceType}) {
    switch (invoiceType) {
        case 'RR':
            yield navigateTo(providerRoute(tenantId, providerId, '/invoices/add-rr'));
            break;
        case 'VAT':
            yield navigateTo(providerRoute(tenantId, providerId, '/invoices/add-vat'));
            break;
        default:
            throw new Error(`Unknown invoice type ${invoiceType}`);
    }
}

function* handleOpenEditRrInvoiceForm({tenantId, providerId, rrInvoiceId}) {
    yield navigateTo(providerRoute(tenantId, providerId, `/invoices/${rrInvoiceId}/edit`));
}

function* handleOpenRrInvoicePreview({tenantId, providerId, rrInvoiceId}) {
    yield navigateTo(providerRoute(tenantId, providerId, `/invoices/${rrInvoiceId}/`));
}

function* handleOpenContainerSharesList({tenantId, providerId}) {
    yield navigateTo(providerRoute(tenantId, providerId, '/container-shares/'));
}

function* handleOpenAddContainerShareForm({tenantId, providerId}) {
    yield navigateTo(providerRoute(tenantId, providerId, '/container-shares/add'));
}

function* handleOpenEditContainerShareForm({tenantId, providerId, containerShareId}) {
    yield navigateTo(providerRoute(tenantId, providerId, `/container-shares/${containerShareId}/`));
}

function* handleOpenPurchasersList({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/purchasers/'));
}

function* handleAddPurchaserForm({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/purchasers/add'));
}

function* handleOpenPurchaserPreview({tenantId, purchaserId}) {
    yield navigateTo(purchaserRoute(tenantId, purchaserId, '/'));
}

function* handleOpenInvoicesList({tenantId, purchaserId}) {
    yield navigateTo(purchaserRoute(tenantId, purchaserId, '/invoices/'));
}

function* handleOpenAddInvoiceForm({tenantId, purchaserId}) {
    yield navigateTo(purchaserRoute(tenantId, purchaserId, '/invoices/add'));
}

function* handleOpenEditInvoiceForm({tenantId, purchaserId, invoiceId}) {
    yield navigateTo(purchaserRoute(tenantId, purchaserId, `/invoices/${invoiceId}/edit`));
}

function* handleInvoicePreview({tenantId, purchaserId, invoiceId}) {
    yield navigateTo(purchaserRoute(tenantId, purchaserId, `/invoices/${invoiceId}/`));
}

function* handleProductList({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/products/'));
}

function* handleOpenAddProductForm({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/products/add'));
}

function* handleOpenEditProductForm({tenantId, productId}) {
    yield navigateTo(tenantRoute(tenantId, `/products/${productId}`));
}

function* handleOpenRrInvoicesReport({tenantId}) {
    yield navigateTo(tenantRoute(tenantId,'/reports/rr-invoices'));
}

function* handleOpenUnpaidRrInvoices({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/rr-invoices/unpaid'))
}

function* handleOpenInvoicesReport({tenantId}) {
    yield navigateTo(tenantRoute(tenantId,'/reports/invoices'));
}

function* handleOpenUnpaidInvoices({tenantId}) {
    yield navigateTo(tenantRoute(tenantId,'/invoices/unpaid'));
}

function* handleInviteUser() {
    yield navigateTo('/');
}

function* handleOpenAccountSettings({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/account-settings'));
}

function* handleOpenUsersList({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/users/'))
}

function* handleOpenAddUserForm({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/users/add'));
}

function* handleOpenEditUserForm({tenantId, userId}) {
    yield navigateTo(tenantRoute(tenantId, `/users/${userId}`));
}

function* handleOpenAddPaymentForm({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/payments/add'));
}

function* handleOpenContainerList({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/containers/'));
}

function* handleOpenAddContainerForm({tenantId}) {
    yield navigateTo(tenantRoute(tenantId, '/containers/add'));
}

function* handleOpenEditContainerForm({tenantId, containerId}) {
    yield navigateTo(tenantRoute(tenantId, `/containers/${containerId}`));
}

function* handleOpenProvisionList({tenantId, purchaserId}) {
    yield navigateTo(purchaserRoute(tenantId, purchaserId, '/provisions/'));
}

function* handleOpenAddProvisionForm({tenantId, purchaserId}) {
    yield navigateTo(purchaserRoute(tenantId, purchaserId, '/provisions/add'));
}

function* handleOpenEditProvisionForm({tenantId, purchaserId, id}) {
    yield navigateTo(purchaserRoute(tenantId, purchaserId, `/provisions/${id}`));
}

const purchasersPathRegex = /^\/purchasers\/(\d+)(\/.*)/;
const invoicesPathRegex = /^\/invoices\/(\d+)(\/.*)/;
const provisionsPathRegex = /^\/provisions\/(\d+)/;
function* handlePurchaserNavigation(tenantId, originalPath) {
    if (!purchasersPathRegex.test(originalPath)) {
        return false;
    }

    const parts = originalPath.match(purchasersPathRegex);
    const purchaserId = parts[1];
    const path = parts[2];

    if (path === '/') {
        yield put(openPurchaserPreview(tenantId, purchaserId));
    } else if (path === '/edit'){
        yield put(openEditPurchaserForm(tenantId, purchaserId));
    } else if (path === '/invoices/') {
        yield put(openInvoicesList(tenantId, purchaserId));
    } else if (path === '/invoices/add') {
        yield put(openAddInvoiceForm(tenantId, purchaserId));
    } else if (invoicesPathRegex.test(path)) {
        const invoiceParts = path.match(invoicesPathRegex);
        const invoiceId = invoiceParts[1];
        const invoicePath = invoiceParts[2];
        if (invoicePath === '/') {
            yield put(openInvoicePreview(tenantId, purchaserId, invoiceId));
        } else if (invoicePath === '/edit') {
            yield put(openEditInvoiceForm(tenantId, purchaserId, invoiceId));
        } else {
            return false;
        }
    } else if (path === '/provisions/') {
        yield put(openProvisionList(tenantId, purchaserId));
    } else if (path === '/provisions/add') {
        yield put(openAddProvisionForm(tenantId, purchaserId));
    } else if (provisionsPathRegex.test(path)) {
        const provisionParts = path.match(provisionsPathRegex);
        yield put(openEditProvisionForm(tenantId, purchaserId, provisionParts[1]));
    } else {
        return false;
    }

    return true;
}

const providersPathRegex = /^\/providers\/(\d+)(\/.*)/;
const rrInvoicesPathRegex = /^\/invoices\/(\d+)(\/.*)/;
const containerSharesPathRegex = /^\/container-shares\/(\d+)(\/.*)/;
const dealsPathRegex = /^\/deals\/(\d+)/;

function* handleProviderNavigation(tenantId, originalPath) {
    if (!providersPathRegex.test(originalPath)) {
        return false;
    }
    const parts = originalPath.match(providersPathRegex);
    const providerId = parts[1];
    const path = parts[2];

    if (path === '/') {
        yield put(openProviderPreview(tenantId, providerId));
    } else if (path === '/edit') {
        yield put(openEditProviderForm(tenantId, providerId));
    } else if (path === '/deals/') {
        yield put(openDealsList(tenantId, providerId));
    } else if (path === '/deals/add') {
        yield put(openAddDealForm(tenantId, providerId))
    } else if (dealsPathRegex.test(path)) {
        const dealsParts = path.match(dealsPathRegex);
        const dealPositionId = dealsParts[1];
        yield put(openEditDealForm(tenantId, providerId, dealPositionId));
    } else if (path === '/invoices/') {
        yield put(openRrInvoicesList(tenantId, providerId));
    } else if (path === '/invoices/add-rr') {
        yield put(openAddRrInvoiceForm(tenantId, providerId, 'RR'));
    } else if (path === '/invoices/add-vat') {
        yield put(openAddRrInvoiceForm(tenantId, providerId, 'VAT'));
    } else if(rrInvoicesPathRegex.test(path)) {
        const invoicesParts = path.match(rrInvoicesPathRegex);
        const invoiceId = invoicesParts[1];
        const invoicePath = invoicesParts[2];

        if (invoicePath === '/') {
            yield put(openRrInvoicePreview(tenantId, providerId, invoiceId));
        } else if (invoicePath === '/edit'){
            yield put(openEditRrInvoiceForm(tenantId, providerId, invoiceId));
        } else {
            return false;
        }
    } else if (path === '/container-shares/') {
        yield put(openContainerSharesList(tenantId, providerId));
    } else if (path === '/container-shares/add') {
        yield put(openAddContainerShareForm(tenantId, providerId));
    } else if (containerSharesPathRegex.test(path)) {
        const containerShareParts = path.match(containerSharesPathRegex);
        const containerShareId = containerShareParts[1];
        yield put(openEditContainerShareForm(tenantId, providerId, containerShareId));
    } else {
        return false;
    }
    return true;
}

const tenantsPathRegex = /^\/tenants\/([0-9a-zA-Z]{5,})(\/.*)$/;
const productsPathRegex = /^\/products\/(\d+)/;
const containersPathRegex = /^\/containers\/(\d+)/;
const usersPathRegex = /^\/users\/([\w|]+)/;


function* handleTenantNavigation(originalPath) {
    if (!tenantsPathRegex.test(originalPath)) {
        return false;
    }

    const parts = originalPath.match(tenantsPathRegex);
    const tenantId = parts[1];
    const path = parts[2];

    if (path === '/') {
        yield put(openTenantPreview(tenantId));
    } else if (path === '/edit') {
        yield put(openEditTenantForm(tenantId));
    } else if (path === '/providers/') {
        yield put(openProviderList(tenantId));
    } else if (path === '/providers/add') {
        yield put(openAddProviderForm(tenantId));
    } else if (yield handleProviderNavigation(tenantId, path)) {
        // nothing
    } else if (path === '/purchasers/'){
        yield put(openPurchasersList(tenantId));
    } else if (path === '/purchasers/add') {
        yield put(openAddPurchaserForm(tenantId))
    } else if (yield handlePurchaserNavigation(tenantId, path)) {
        // nothing
    } else if (path === '/products/') {
        yield put(openProductList(tenantId));
    } else if (path === '/products/add') {
        yield put(openAddProductForm(tenantId))
    } else if (productsPathRegex.test(path)) {
        const productParts = path.match(productsPathRegex);
        yield put(openEditProductForm(tenantId, productParts[1]));
    } else if (path === '/reports/rr-invoices') {
        yield put(openRrInvoicesReport(tenantId));
    } else if (path === '/reports/invoices') {
        yield put(openInvoicesReport(tenantId));
    } else if (path === '/rr-invoices/unpaid') {
        yield put(openUnpaidRrInvoicesSummary(tenantId));
    } else if (path === '/invoices/unpaid') {
        yield put(openUnpaidInvoicesSummary(tenantId));
    } else if (path === '/account-settings') {
        yield put(openAccountSettings(tenantId));
    } else if (path === '/users/') {
        yield put(openUsersList(tenantId))
    } else if (path === '/users/add') {
        yield put(openAddUserForm(tenantId));
    } else if (path.match(usersPathRegex)) {
        const userParts = path.match(usersPathRegex);
        yield put(openEditUserForm(tenantId, userParts[1]));
    } else if (path === '/payments/add') {
        yield put(openAddPaymentForm(tenantId));
    } else if (path === '/containers/') {
        yield put(openContainerList(tenantId));
    } else if (path === '/containers/add') {
        yield put(openAddContainerForm(tenantId));
    } else if (containersPathRegex.test(path)) {
        const containerParts = path.match(containersPathRegex);
        yield put(openEditContainerForm(tenantId, containerParts[1]));
    } else if (path === '/feature-flags/') {
        yield put(openTenantFeatureFlagsForm(tenantId))
    } else {
        return false;
    }
    return true;
}

function* handleLoggedInNavigation({path}) {
    console.info('handleLoggedInNavigation', path);
    if (path === '/tenants/add') {
        yield put(openAddTenantForm());
        return;
    }

    if (yield handleTenantNavigation(path)) {
        return;
    }
    if (path === '/tenants/') {
        yield put(openTenantsList());
    } else if (path === '/' || path === '') {
        yield put(inviteUser());
    } else {
        // TODO: Open 404
        console.warn('unhandled route', path);
    }
}

function* handleLogin() {
    const channel = eventChannel(emit => {
        let dispose  = history.listen((location, action) => {
            emit({path: location.pathname, action});
        });

        return () => {
            dispose();
        }
    });
    yield fork(handleLoggedInNavigation, {path: history.location.pathname, action: history.action});

    yield all([
        takeLatest(channel, handleLoggedInNavigation),
        takeLatest(OPEN_ADD_TENANT_FORM, handleOpenTenantForm),
        takeLatest(OPEN_TENANTS_LIST, handleOpenTenantList),
        takeLatest(OPEN_TENANT_PREVIEW, handleOpenTenantPreview),
        takeLatest(OPEN_EDIT_TENANT_FORM, handleOpenEditTenantForm),
        takeLatest(OPEN_TENANT_FEATURE_FLAGS_FORM, handleOpenTenantFeatureFlagsForm),
        takeLatest(OPEN_PROVIDER_LIST, handleOpenProvidersList),
        takeLatest(OPEN_ADD_PROVIDER_FORM, handleOpenAddProvidersForm),
        takeLatest(OPEN_EDIT_PROVIDER_FORM, handleOpenEditProviderForm),
        takeLatest(OPEN_PROVIDER_PREVIEW, handleOpenProviderPreview),
        takeLatest(OPEN_ADD_DEAL_FORM, handleOpenAddDealForm),
        takeLatest(OPEN_EDIT_DEAL_FORM, handleEditDealForm),
        takeLatest(OPEN_RR_INVOICES_LIST, handleOpenRrInvoicesList),
        takeLatest(OPEN_ADD_RR_INVOICE_FORM, handleOpenAddRrInvoiceForm),
        takeLatest(OPEN_EDIT_RR_INVOICE_FORM, handleOpenEditRrInvoiceForm),
        takeLatest(OPEN_RR_INVOICE_PREVIEW, handleOpenRrInvoicePreview),
        takeLatest(OPEN_PURCHASERS_LIST, handleOpenPurchasersList),
        takeLatest(OPEN_ADD_PURCHASER_FORM, handleAddPurchaserForm),
        takeLatest(OPEN_PURCHASER_PREVIEW, handleOpenPurchaserPreview),
        takeLatest(OPEN_INVOICES_LIST, handleOpenInvoicesList),
        takeLatest(OPEN_ADD_INVOICE_FORM, handleOpenAddInvoiceForm),
        takeLatest(OPEN_EDIT_INVOICE_FORM, handleOpenEditInvoiceForm),
        takeLatest(OPEN_DEALS_LIST, handleOpenDealsList),
        takeLatest(OPEN_INVOICE_PREVIEW, handleInvoicePreview),
        takeLatest(OPEN_PRODUCT_LIST, handleProductList),
        takeLatest(OPEN_ADD_PRODUCT_FORM, handleOpenAddProductForm),
        takeLatest(OPEN_EDIT_PRODUCT_FORM, handleOpenEditProductForm),
        takeLatest(OPEN_RR_INVOICES_REPORT, handleOpenRrInvoicesReport),
        takeLatest(OPEN_UNPAID_RR_INVOICES_SUMMARY, handleOpenUnpaidRrInvoices),
        takeLatest(OPEN_INVOICES_REPORT, handleOpenInvoicesReport),
        takeLatest(OPEN_UNPAID_INVOICES_SUMMARY, handleOpenUnpaidInvoices),
        takeLatest(INVITE_USER, handleInviteUser),
        takeLatest(OPEN_ACCOUNT_SETTINGS, handleOpenAccountSettings),
        takeLatest(OPEN_USERS_LIST, handleOpenUsersList),
        takeLatest(OPEN_ADD_USER_FORM, handleOpenAddUserForm),
        takeLatest(OPEN_EDIT_USER_FORM, handleOpenEditUserForm),
        takeLatest(OPEN_ADD_PAYMENT_FORM, handleOpenAddPaymentForm),
        takeLatest(OPEN_CONTAINER_LIST, handleOpenContainerList),
        takeLatest(OPEN_ADD_CONTAINER_FORM, handleOpenAddContainerForm),
        takeLatest(OPEN_EDIT_CONTAINER_FORM, handleOpenEditContainerForm),
        takeLatest(OPEN_CONTAINER_SHARES_LIST, handleOpenContainerSharesList),
        takeLatest(OPEN_ADD_CONTAINER_SHARE_FORM, handleOpenAddContainerShareForm),
        takeLatest(OPEN_EDIT_CONTAINER_SHARE_FORM, handleOpenEditContainerShareForm),
        takeLatest(OPEN_PROVISION_LIST, handleOpenProvisionList),
        takeLatest(OPEN_ADD_PROVISION_FORM, handleOpenAddProvisionForm),
        takeLatest(OPEN_EDIT_PROVISION_FORM, handleOpenEditProvisionForm)
    ]);
}

function* handleLoggedOutNavigation({path}) {
    switch(path) {
        case '/':
            yield put(openRegisterForm());
            break;
        case '/login':
            yield put(openLoginForm());
            break;
        case '/reset-password':
            yield put(openResetPasswordForm());
            break;
        default:
            // TODO: Redirect to login with route argument
            console.warn('unknown route', path);
            break;
    }
}

function* handleOpenRegisterForm() {
    yield navigateTo('/');
}

function* handleOpenLoginForm() {
    yield navigateTo('/login');
}

function* handleOpenResetPasswordForm() {
    yield navigateTo('/reset-password');
}

function* handleLogout() {
    const channel = eventChannel(emit => {
        let dispose  = history.listen((location, action) => {
            emit({path: location.pathname, action});
        });

        return () => {
            dispose();
        }
    });
    yield fork(handleLoggedOutNavigation, {path: history.location.pathname, action: history.action});
    yield all([
        takeLatest(channel, handleLoggedOutNavigation),
        takeLatest(OPEN_REGISTER_FORM, handleOpenRegisterForm),
        takeLatest(OPEN_LOGIN_FORM, handleOpenLoginForm),
        takeLatest(OPEN_RESET_PASSWORD_FORM, handleOpenResetPasswordForm)
    ]);
}

function* handleAuthStatusChange(cancellationWrapper, action) {
    if(cancellationWrapper){
        yield cancellationWrapper();
    }
    switch(action.authStatus) {
        case LOGGED_IN:
            yield handleLogin();
            break;
        case NOT_LOGGED_IN:
        case LOGGED_OUT:
            yield handleLogout();
            break;
        default:
            break;
    }
}

export function* routerSaga() {
    const currentStatus = yield select(state => state.authStatus);
    let cancellationWrapper = undefined;
    if (currentStatus) {
        let cancellation = yield fork(handleAuthStatusChange, null, {authStatus: currentStatus});
        cancellationWrapper = function* () {
           if (cancellation){
               yield cancel(cancellation);
               cancellation = undefined;
           }
        }
    }
    yield takeLatest(SET_AUTH_STATUS, handleAuthStatusChange, cancellationWrapper);
}