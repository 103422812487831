import React, {lazy, Suspense} from 'react';
import {CssBaseline} from "@material-ui/core";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import LoadingScreen from '../ViewComponents/LoadingScreen';
import LoggedOutDashboard from './LoggedOutDashboard';
import {CHECKING,LOGGED_IN,NOT_LOGGED_IN, LOGGED_OUT} from '../State/Actions/setAuthStatus';

const Dashboard = lazy(() => import('./Dashboard'));

const theme = createTheme({
    palette: {
        primary: {
            main: '#055902',
            light: '#8BA651',
        },
        secondary: {
            main: '#A61103',
            light: '#F29C94',
        }
    }
});

export const App = () => {
    const authStatus = useSelector(o => o.authStatus);

    let content = undefined;
    switch (authStatus) {
        case LOGGED_IN:
            content = <Suspense fallback={<LoadingScreen message="Proszę czekać"/>}>
                <Dashboard />
            </Suspense>;
            break;
        case CHECKING:
            const hackToLoadDashboard = <div style={{display: 'none'}}>
                <Suspense fallback={<></>}>
                    <Dashboard/>
                </Suspense>
            </div>;
            content = <>
                {hackToLoadDashboard}
                <LoadingScreen message="Proszę czekać"/>
            </>;
            break;
        case LOGGED_OUT:
        case NOT_LOGGED_IN:
            content = <LoggedOutDashboard/>;
            break;
        default:
            throw new Error(`Nothing to display for status: ${authStatus}`);
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <ThemeProvider theme={theme}>
                {content}
            </ThemeProvider>
        </React.Fragment>
    );
};

export default App;
