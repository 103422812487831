import React from "react";
import {Alert} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        marginBottom: theme.spacing(3)
    },
    unStyledList: {
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: 0
    },
    unStyledListItem: {
        listStyle: 'none'
    }
})

const useStyles = makeStyles(styles);

export function ErrorsPanel({errors}) {
    const classes = useStyles();

    if (!errors) {
        return  <React.Fragment/>;
    }
    return <Alert severity="error" className={classes.root}>
        <ul className={classes.unStyledList}>
            {errors.map((o, i) => <li key={i} className={classes.unStyledListItem}>{o.errorMessage}</li>)}
        </ul>
    </Alert>
}
export default  ErrorsPanel;