import {all, call, put, select, takeLatest} from "redux-saga/effects";
import {OPEN_PROVISION_LIST} from "../Actions/openProvisionList";
import {getPurchaserUrl} from "./utils";
import {getAxiosConfig, handleValidation} from "./tenantsSaga";
import {busyCall} from "./businessSaga";
import axios from "../../exios";
import {resetCurrentPurchaserWindow} from "../Actions/resetCurrentPurchaserWindow";
import {invalidateProducts} from "./productListSaga";
import {OPEN_ADD_PROVISION_FORM} from "../Actions/openAddProvisionForm";
import {ADD_PROVISION} from "../Actions/addProvision";
import {DELETE_PROVISION} from "../Actions/deleteProvision";
import {OPEN_EDIT_PROVISION_FORM} from "../Actions/openEditProvisionForm";
import {UPDATE_PROVISION} from "../Actions/updateProvision";

function getConfig (tenantId) {
    return getAxiosConfig(tenantId, 'https://api.skupomat.pl');
}

function* handleOpenProvisionList({tenantId, purchaserId}) {
    const config = yield getConfig(tenantId);
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {provisionList: { loading: true }}));
    yield invalidateProducts(tenantId);
    const {data} = yield busyCall(axios.get, getPurchaserUrl(`/provisions`, tenantId, purchaserId), config);

    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {provisionList: { loading: false, data }}));
}

function* handleOpenAddProvisionForm({tenantId, purchaserId}) {
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {addProvisionForm: {}}));
    yield invalidateProducts(tenantId);
}

function* handleAddProvision({tenantId, purchaserId, provision}) {
    const oldForm = yield select(state => state.currentWindow.addProvisionForm);
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {addProvisionForm: { ...oldForm, saving: true }}));
    const config = yield getConfig(tenantId);
    const response = yield busyCall(axios.post, getPurchaserUrl(`/provisions`, tenantId, purchaserId), provision, config);
    const {errors} = yield call(handleValidation, response);
    if (errors) {
        yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {addProvisionForm: { ...oldForm, errors }}));
    } else {
        yield handleOpenProvisionList({tenantId, purchaserId});
    }
}

function* handleDeleteProvision({tenantId, purchaserId, id}) {
    const config = yield getConfig(tenantId);
    const response = yield busyCall(axios.delete, getPurchaserUrl(`/provisions/${id}`, tenantId, purchaserId), config);
    const {errors} = yield call(handleValidation, response);
    if (errors) {
        const oldList = yield select(state => state.currentWindow.provisionList);
        yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {provisionList: { ...oldList, errors }}));
    } else {
        yield handleOpenProvisionList({tenantId, purchaserId});
    }
}

function* handleOpenEditProvisionForm({tenantId, purchaserId, id}) {
    const config = yield getConfig(tenantId);
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {}));
    const {data} = yield busyCall(axios.get, getPurchaserUrl(`/provisions/${id}`, tenantId, purchaserId), config);

    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {editProvisionForm: { formData: data, id }}));
}

function* handleUpdateProvision({tenantId, purchaserId, id, data}) {
    const oldForm = yield select(state => state.currentWindow.editProvisionForm);
    yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {editProvisionForm: { ...oldForm, saving: true}}));
    const config = yield getConfig(tenantId);
    const response = yield busyCall(axios.put, getPurchaserUrl(`/provisions/${id}`, tenantId, purchaserId), data, config);
    const {errors} = yield call(handleValidation, response);
    if (errors) {
       yield put(resetCurrentPurchaserWindow(tenantId, purchaserId, {editProvisionForm: { ...oldForm, errors }}));
    } else {
        yield handleOpenProvisionList({tenantId, purchaserId});
    }
}

export function* provisionsSaga() {
    yield all([
        takeLatest(OPEN_PROVISION_LIST, handleOpenProvisionList),
        takeLatest(OPEN_ADD_PROVISION_FORM, handleOpenAddProvisionForm),
        takeLatest(ADD_PROVISION, handleAddProvision),
        takeLatest(DELETE_PROVISION, handleDeleteProvision),
        takeLatest(OPEN_EDIT_PROVISION_FORM, handleOpenEditProvisionForm),
        takeLatest(UPDATE_PROVISION, handleUpdateProvision)
    ])
}