import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import RawEmailForm from '../ViewComponents/EmailForm';
import RawPasswordForm from '../ViewComponents/PasswordForm';
import RawRegisterForm from '../ViewComponents/RegisterForm';
import {checkEmail} from "../State/Actions/checkEmail";
import {register} from '../State/Actions/register';
import {openRegisterForm} from "../State/Actions/openRegisterForm";

function EmailForm({step, email, errors, checking, exists}) {
    const dispatch = useDispatch();
    if (step === 0) {
        return <RawEmailForm errors={errors} initialEmail={email} checking={checking} exists={exists} onSubmit={email => dispatch(checkEmail(email))}/>
    } else {
        return <React.Fragment/>
    }
}

function PasswordForm({step, errors, email, checking}) {
    const dispatch = useDispatch();
    if (step === 1) {
        return <RawPasswordForm
            errors={errors}
            checking={checking}
            onSubmit={password => dispatch(register(email, password))}
            onPrevious={() => dispatch(openRegisterForm(email))}/>
    } else {
        return <></>
    }
}

export function RegisterForm() {
    const {registerForm} = useSelector(o => o.currentWindow);

    if (!registerForm) {
        return <></>
    }

    return <RawRegisterForm>
        <EmailForm {...registerForm}/>
        <PasswordForm {...registerForm} />
    </RawRegisterForm>;
}

export default RegisterForm;