import React from 'react';
import {Button, CircularProgress, makeStyles} from "@material-ui/core";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
   root: {
       textAlign: 'right',
       paddingTop: theme.spacing(2)
   },
   button: {
       marginLeft: theme.spacing(1)
   }
}));

export function WizardFooter({checking, onPrevious, showPrevious=true}) {
    const classes = useStyles();
    return <div className={classes.root}>
        {showPrevious && <Button
            variant="outlined"
            color="secondary"
            startIcon={<ChevronLeft/>}
            onClick={onPrevious}
            className={classes.button}>
            Wstecz
        </Button>}
        <Button
            variant="contained"
            color="primary"
            type="submit"
            startIcon={checking && <CircularProgress color="inherit" size={14}/>}
            endIcon={<ChevronRight/>}
            className={classes.button}>
            Dalej
        </Button>
    </div>
}

export default WizardFooter;