import axios from "../../exios";
import {all, call, select, takeLatest, put} from "redux-saga/effects";
import {OPEN_PRODUCT_LIST, openProductList} from "../Actions/openProductList";
import {OPEN_ADD_PRODUCT_FORM} from '../Actions/openAddProductForm';
import {OPEN_EDIT_PRODUCT_FORM} from "../Actions/openEditProductForm";
import {resetCurrentTenantWindow} from "../Actions/resetCurrentTenantWindow";
import {getAxiosConfig, handleValidation} from "./tenantsSaga";
import {ADD_PRODUCT} from "../Actions/addProduct";
import {UPDATE_PRODUCT} from "../Actions/updateProduct";
import {getApiUrl, updateTenantEntity} from "./utils";
import {busyCall, busyWrapper} from "./businessSaga";
import {SET_PRODUCT_VISIBILITY} from "../Actions/setProductVisibility";
import {loadCacheEntry} from "../Actions/loadCacheEntry";

function getConfig (tenantId) {
    return getAxiosConfig(tenantId, 'https://api.skupomat.pl');
}

export function* invalidateProducts(tenantId) {
    yield put(loadCacheEntry(tenantId, 'products', getApiUrl('/products', tenantId)));
}

function* handleOpenProductList({tenantId}) {
    yield put(resetCurrentTenantWindow(tenantId, {productList: {  }}));
    yield invalidateProducts(tenantId);
}

function* handleOpenAddProductForm({tenantId}) {
    const product = {}
    yield put(resetCurrentTenantWindow(tenantId, {addProductForm: { product }}));
    yield put(loadCacheEntry(tenantId, 'promotionFunds', getApiUrl('/promotion-funds')));
    yield put(loadCacheEntry(tenantId, 'productTemplates', getApiUrl('/product-templates')));
}

function* addProduct(tenantId, productData) {
    const config = yield getConfig(tenantId);
    const response = yield call(axios.post, getApiUrl('/products', tenantId), productData, config);
    const { errors } = yield call(handleValidation, response);
    return errors;
}

function* handleAddProduct({tenantId, productData}) {
    const oldProductForm = yield select(o => o.currentWindow.addProductForm);
    yield put(resetCurrentTenantWindow(tenantId, {addProductForm:{...oldProductForm, saving: true}}));
    const errors = yield addProduct(tenantId, productData)
    if (errors) {
        const addProductForm = {
            ...oldProductForm,
            errors
        }
        yield put(resetCurrentTenantWindow(tenantId, {addProductForm}));
    } else {
        yield put(openProductList(tenantId));
    }
}

function* handleOpenEditProductForm({tenantId, productId}) {
    const config = yield getConfig(tenantId);
    const {data : product} = yield busyCall(axios.get, getApiUrl(`/products/${productId}`, tenantId), config);
    yield put(loadCacheEntry(tenantId, 'promotionFunds', getApiUrl('/promotion-funds')));
    yield put(loadCacheEntry(tenantId, 'productTemplates', getApiUrl('/product-templates')));
    yield put(resetCurrentTenantWindow(tenantId, {editProductForm: { product, productId }}))
}

function* handleUpdateProduct({tenantId, productId, productData}) {
    yield updateTenantEntity(
        tenantId,
        productData,
        'editProductForm',
        `/products/${productId}`,
        () => openProductList(tenantId));
}

function* handleSetProductVisibility({tenantId, productId, visible}) {
    const config = yield getConfig(tenantId);
    yield call(axios.patch, getApiUrl(`/products/${productId}`, tenantId), {visible}, config);
    yield invalidateProducts(tenantId);
}

export function* productListSaga() {
    yield all([
        takeLatest(OPEN_PRODUCT_LIST, handleOpenProductList),
        takeLatest(OPEN_ADD_PRODUCT_FORM, handleOpenAddProductForm),
        takeLatest(ADD_PRODUCT, busyWrapper(handleAddProduct)),
        takeLatest(OPEN_EDIT_PRODUCT_FORM, handleOpenEditProductForm),
        takeLatest(UPDATE_PRODUCT, handleUpdateProduct),
        takeLatest(SET_PRODUCT_VISIBILITY, handleSetProductVisibility)
    ]);
}