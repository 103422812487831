export function tenantRoute(tenantId, route) {
    return `/tenants/${tenantId}${route}`;
}

export function providerRoute(tenantId, providerId, route) {
    return tenantRoute(tenantId, `/providers/${providerId}${route}`);
}

export function purchaserRoute(tenantId, purchaserId, route) {
    return tenantRoute(tenantId, `/purchasers/${purchaserId}${route}`);
}