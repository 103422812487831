import {all, put, takeLatest} from 'redux-saga/effects';
import axios from "../../exios";
import {OPEN_ADD_CONTAINER_SHARE_FORM} from "../Actions/openAddContainerShareForm";
import {OPEN_CONTAINER_SHARES_LIST, openContainerSharesList} from "../Actions/openContainerSharesList";
import {getAxiosConfig} from "./tenantsSaga";
import {resetCurrentProviderWindow} from "../Actions/resetCurrentProviderWindow";
import {busyCall} from "./businessSaga";
import {addProviderEntity, getProviderUrl, updateProviderEntity} from "./utils";
import { invalidateContainers} from "./containersSaga";
import {ADD_CONTAINER_SHARE} from "../Actions/addContainerShare";
import {DELETE_CONTAINER_SHARE} from "../Actions/deleteContainerShare";
import {OPEN_EDIT_CONTAINER_SHARE_FORM} from "../Actions/openEditContainerShareForm";
import {UPDATE_CONTAINER_SHARE} from "../Actions/updateContainerShare";

function getConfig (tenantId) {
    return getAxiosConfig(tenantId, 'https://api.skupomat.pl');
}

function* handleOpenContainerSharesList({tenantId, providerId}) {
    yield put(resetCurrentProviderWindow(tenantId, providerId, {containerSharesList: {loading: true}}));
    yield invalidateContainers(tenantId);

    const config = yield getConfig(tenantId);
    const {data} = yield busyCall(axios.get, getProviderUrl('/container-shares', tenantId, providerId), config);
    yield put(resetCurrentProviderWindow(tenantId, providerId, {containerSharesList: {data}}));
}

function* handleOpenAddContainerShareForm({tenantId, providerId}) {
    yield invalidateContainers(tenantId);
    yield put(resetCurrentProviderWindow(tenantId, providerId, {addContainerShareForm: {}}));
}

function* handleAddContainerShare({tenantId, providerId, containerShareData}) {
    yield addProviderEntity(
        tenantId,
        providerId,
        containerShareData,
        'addContainerShareForm',
        `/container-shares`,
        () => openContainerSharesList(tenantId, providerId));
}

function* handleDeleteContainerShare({tenantId, providerId, containerShareId}) {
    yield put(resetCurrentProviderWindow(tenantId, providerId, {containerSharesList: {loading: true}}));
    const config = yield getConfig(tenantId);
    yield busyCall(axios.delete, getProviderUrl(`/container-shares/${containerShareId}`, tenantId, providerId), config);
    yield put(openContainerSharesList(tenantId, providerId));
}

function* handleOpenEditContainerShareForm({tenantId, providerId, containerShareId}) {
    yield put(resetCurrentProviderWindow(tenantId, providerId, {}));
    yield invalidateContainers(tenantId);
    const config = yield getConfig(tenantId);
    const {data} = yield busyCall(axios.get, getProviderUrl(`/container-shares/${containerShareId}`, tenantId, providerId), config);
    yield put(resetCurrentProviderWindow(tenantId, providerId, {editContainerShareForm: {containerData: data, id: containerShareId}}));
}

function* handleUpdateContainerShare({tenantId, providerId, containerShareId,containerShareData}) {
    yield updateProviderEntity(
        tenantId,
        providerId,
        containerShareData,
        'editContainerShareForm',
        `/container-shares/${containerShareId}`,
        () => openContainerSharesList(tenantId, providerId));
}

export function* containerShareSaga() {
    yield all([
        takeLatest(OPEN_CONTAINER_SHARES_LIST, handleOpenContainerSharesList),
        takeLatest(OPEN_ADD_CONTAINER_SHARE_FORM, handleOpenAddContainerShareForm),
        takeLatest(ADD_CONTAINER_SHARE, handleAddContainerShare),
        takeLatest(DELETE_CONTAINER_SHARE, handleDeleteContainerShare),
        takeLatest(OPEN_EDIT_CONTAINER_SHARE_FORM, handleOpenEditContainerShareForm),
        takeLatest(UPDATE_CONTAINER_SHARE, handleUpdateContainerShare)
    ]);
}