import React, {useState} from 'react';
import {Button, Card, CardActions, CardContent, CardHeader, Grid, TextField, Typography} from "@material-ui/core";
import ErrorsPanel from "./ErrorsPanel";

export function ResetPasswordForm({errors, onSubmit}) {
    const [email, setEmail] = useState();

    const canSubmit = !!email;

    const onFormSubmit = e => {
        e.preventDefault();

        if(!canSubmit) return;

        onSubmit(email);
    };

    return <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} md={6} lg={4}>
            <form onSubmit={onFormSubmit}>
                <Card>
                    <CardHeader title="Zapomniałeś hasła?"/>
                    <CardContent>
                        <ErrorsPanel errors={errors}/>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="body2" align="center">Podaj adres email użyty przy rejestracji.</Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    label="Adres email"
                                    type="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button type="submit" color="primary" variant="contained" fullWidth>
                            Wyślij link resetujący
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </Grid>
    </Grid>;
}

export default ResetPasswordForm;