import {all, delay, put, takeEvery } from 'redux-saga/effects';
import {v4} from "uuid";
import {BLINK_NOTIFICATION} from "../Actions/blinkNotification";
import {addNotification} from "../Actions/addNotification";
import {removeNotification} from "../Actions/removeNotification";

function* handleBlinkNotification({message, duration}) {
    const id = v4();

    yield put(addNotification(id, message));
    yield delay(duration * 1000);
    yield put(removeNotification(id));
}

export function* notificationSaga() {
    yield all([
        takeEvery(BLINK_NOTIFICATION, handleBlinkNotification)
    ])
}