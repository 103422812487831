import { tenantsSaga } from './Sagas/tenantsSaga';
import { providerSaga } from './Sagas/providersSaga';
import { productListSaga } from './Sagas/productListSaga';
import { dealsSaga } from './Sagas/dealsSaga';
import { purchasersSaga } from './Sagas/purchasersSaga';
import { invoicesSaga } from './Sagas/invoicesSaga';
import { rrInvoicesSaga } from './Sagas/rrInvoiceSaga';
import { reportsSaga } from  './Sagas/reportsSaga';
import { usersSaga } from './Sagas/usersSaga';
import { paymentsSaga } from './Sagas/paymentsSaga';
import { containersSaga } from "./Sagas/containersSaga";
import { containerShareSaga } from "./Sagas/containerShareSaga";
import { provisionsSaga } from "./Sagas/provisionsSaga";
import { notificationSaga } from "./Sagas/notificationSaga";
import {cacheSaga} from "./Sagas/cacheSaga";


// Router saga should be just before authSaga
import {routerSaga} from "./Sagas/routerSaga";
import {authSaga, determineAuthState} from "./Sagas/authSaga";


const initSagas = (sagaMiddleware) => {
  const allSagas = [
    cacheSaga,
    tenantsSaga,
    providerSaga,
    productListSaga,
    dealsSaga,
    purchasersSaga,
    invoicesSaga,
    rrInvoicesSaga,
    reportsSaga,
    usersSaga,
    paymentsSaga,
    containersSaga,
    containerShareSaga,
    provisionsSaga,
    routerSaga,
    notificationSaga,
    authSaga,
    determineAuthState
  ]
  allSagas.forEach(o => sagaMiddleware.run.bind(sagaMiddleware)(o));
};

export default initSagas;