import {put, call, cancelled} from "redux-saga/effects";
import {getBusy} from "../Actions/getBusy";
import {getRest} from "../Actions/getRest";

function* busyCallGenerator(generator, ...parameters) {
    yield put(getBusy());
    try {
        return yield generator(...parameters);
    } finally {
        // Without calling cancelled here finally block is not called
        if (yield cancelled()) {
            console.log('Cancellation detected');
        }
        yield put(getRest());
    }
}

export const busyCall = (...parameters) => busyCallGenerator(call, ...parameters);
export const busyWrapper = (generator) => (...parameters) => busyCallGenerator(generator, ...parameters);
