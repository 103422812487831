import axios from 'axios';
import {call, delay, put as putAction} from 'redux-saga/effects'
import {setConnectivityStatus} from "./State/Actions/setConnectivityStatus";
import {createLogout} from "./State/Actions/logout";

function* wrap(wrapee) {
    let trial = 1;
    do {
        try {
            const result = yield call(wrapee);

            yield putAction(setConnectivityStatus('OK'));

            return result;
        } catch (Error) {
            if (Error.response) {
                const status = Error.response.status;
                if (status === 401 || status === 403) {
                    yield putAction(createLogout());
                    throw Error;
                }

                if (![408, 502, 503, 504].includes(status)) {
                    yield putAction(setConnectivityStatus('Error'));
                    console.log('Unrecoverable error', Error);
                    return;
                }
            }
            // give up
            if (trial > 4) {
                yield putAction(setConnectivityStatus('Disconnected'));
                console.log('give up', trial, Error);
                throw Error;
            }

            if (trial > 1) {
                yield putAction(setConnectivityStatus('Warn'));
            }

            trial++;
            const timeout = Math.pow(2, trial) * 1000;
            console.log('Error, retrying', Error, timeout);
            yield delay(timeout);
        }
    } while (true)
}

function get(url, config) {
    return wrap(() => axios.get(url, config));
}

function post(url, data, config) {
    return wrap(() => axios.post(url, data, config));
}

function put(url, data, config) {
    return wrap(() => axios.put(url, data, config));
}

function del(url, config) {
    return wrap(() => axios.delete(url, config));
}

function head(url, config) {
    return wrap(() => axios.head(url, config));
}

function patch(url, data, config) {
    return wrap(() => axios.patch(url, data, config));
}

const result = {
    get,
    post,
    put,
    delete: del,
    head,
    patch
};
export default result;