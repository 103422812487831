import { all, call, delay, put, select, takeEvery, takeLatest  } from 'redux-saga/effects';
import {addCacheEntry} from "../Actions/addCacheEntry";
import lscache from 'lscache';
import _ from 'lodash';
import {LOGOUT} from "../Actions/logout";
import {getAxiosConfig} from "./tenantsSaga";
import {busyCall} from "./businessSaga";
import axios from "../../exios";
import {resetCache} from "../Actions/resetCache";
import {LOAD_CACHE_ENTRY} from "../Actions/loadCacheEntry";

function getApiConfig(tenantId) {
    return getAxiosConfig(tenantId, 'https://api.skupomat.pl');
}

function* loadCacheEntry(tenantId, url, etag) {
    // Delay to let other requests to be processed first
    yield delay(10);

    const apiConfig = yield getApiConfig(tenantId);

    const modifiedApiConfig = {
        ...apiConfig,
        headers: {
            ...apiConfig.headers,
            'If-None-Match': etag
        },
        validateStatus: status => (status >= 200 && status < 300) || status === 304 ||  status === 404
    }

    let status;
    let data;
    let responseEtag;
    let attempt = 0;
    do {
        if (attempt !== 0) {
            yield delay(3000);
        }
        const {
            data: responseData,
            status: responseStatus,
            headers: {etag}
        } = yield busyCall(axios.get, url, modifiedApiConfig);
        responseEtag = etag;
        status = responseStatus;
        data = responseData;
        attempt++;
    } while (status === 404 && attempt < 4);

    return {status, data, responseEtag};
}

function* handleLoadCacheEntry({ key, url, tenantId }) {
    const cacheKey = tenantId;
    const lsCacheKey = `${cacheKey}-${key}`;
    let cachedData = yield select(state => {
        const tenantCache = state.cache[cacheKey];
        return tenantCache ? tenantCache[key] : undefined;
    });

    const thirtyHours = 30 * 60;
    if (!cachedData) {
        const stringEntry = lscache.get(lsCacheKey);
        if (stringEntry) {
            const parsed = JSON.parse(stringEntry);
            cachedData = {
                etag: parsed.etag,
                data: parsed.data
            };
            yield put(addCacheEntry(cacheKey, key, parsed.etag, parsed.data, url));
        }
    } else {
        // Refresh timeout
        lscache.set(lsCacheKey, JSON.stringify({etag: cachedData.etag, data: cachedData.data}), thirtyHours);
    }

    const {responseEtag, status, data} = yield loadCacheEntry(tenantId, url, cachedData ? cachedData.etag : undefined);

    if (status === 304) {
        return;
    }

    yield put(addCacheEntry(cacheKey, key, responseEtag, data, url));
    lscache.set(lsCacheKey, JSON.stringify({etag: responseEtag, data}), thirtyHours);
}

function* refreshCache() {
    do {
        const currentTenantId = yield select(state => state.currentWindow?.currentTenant?.id);

        if (!currentTenantId) {
            yield delay(1000);
            continue;
        }
        const tenantCache = yield select(state => state.cache[currentTenantId]);

        if (!tenantCache) {
            yield delay(1000);
            continue;
        }

        const cacheEntries = _.map(tenantCache, ({url, etag}, key) => ({tenantId: currentTenantId, key, url, etag}));

        for (const {tenantId, key, etag, url} of cacheEntries) {
            const twentyNineHoursFiftyEightMinutes = 29 * 60 * 60 * 1000 + 58 * 60 * 1000;
            const cacheExpiry = lscache.getExpiryMilliseconds(key);
            if (cacheExpiry > twentyNineHoursFiftyEightMinutes) {
                continue;
            }
            const {data, responseEtag, status} = yield loadCacheEntry(tenantId, url, etag);

            if (status === 304) {
                continue;
            }

            yield put(addCacheEntry(tenantId, key, responseEtag, data, url));
        }

        const twoMinutes = 120 * 1000;
        yield delay(twoMinutes);
    } while (true);
}

function* handleLogout() {
    lscache.flush();
    yield put(resetCache());
}

export function* cacheSaga() {
    yield all([
        takeEvery(LOAD_CACHE_ENTRY, handleLoadCacheEntry),
        takeLatest(LOGOUT, handleLogout),
        call(refreshCache)
    ]);
}