import React from 'react';
import {
    Card, CardHeader, CardContent,
    Grid,
    Paper,
    makeStyles,
    Typography
} from "@material-ui/core";
import skupomatLogo from './images/skupomat-64.png';

const styles = theme => ({
    leftPanel: {
      padding: theme.spacing(2)
    },
    greetingsCell: {
        minHeight: 400
    },
    greetings: {
        display: 'inline-block',
        margin: '0 auto',
        paddingLeft: theme.spacing(2)
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: `'Alex Brush', cursive`,
        fontSize: '40pt'
    },
    motto: {
        fontFamily: `'Alex Brush', cursive`,
        fontSize: '25pt',
        textAlign: 'center',
        marginTop: theme.spacing(2)
    },
    rightPanel: {
        minHeight: 328
    }

});

const useStyles = makeStyles(styles);

export function RegisterForm({children}) {
    const classes = useStyles();

    return <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6} lg={4}>
            <Paper className={classes.leftPanel}>
                <Grid container direction="column" className={classes.greetingsCell} justifyContent="center" alignItems="center">
                    <Grid item>
                        <p className={classes.logo}><img src={skupomatLogo} alt="Logo"/> Skupomat</p>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" className={classes.greetings}>Serdecznie dziękujemy Ci za zaufanie.<br/>
                            Uważamy że: Nie po  to prowadzisz skup żeby spędzać czas za biurkiem!<br/>
                            Obiecujemy że zrobimy wszystko:
                        </Typography>
                        <p className={classes.motto}>
                            Żeby twoja praca przynosiła owoce
                        </p>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <Card>
                <CardHeader title="Zarejestruj się"/>
                <CardContent>
                    <Grid container direction="column" justifyContent="center" className={classes.rightPanel}>
                        <Grid item>
                            {children}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}

export default RegisterForm;