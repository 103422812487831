import {busyCall} from "./businessSaga";
import axios from "../../exios";
import {delay} from "redux-saga/effects";
import {getAxiosConfig} from "./tenantsSaga";

function getConfig (tenantId) {
    return getAxiosConfig(tenantId, 'https://api.skupomat.pl');
}

export function* downloadFile(tenantId, path, fileName, extraConfig = {}) {
    const baseConfig = yield getConfig(tenantId);
    const config = {
        ...baseConfig,
        ...extraConfig,
        headers: {
          ...baseConfig.headers,
          ...extraConfig.headers
        },
        responseType: 'blob'
    };

    const response = yield busyCall(axios.get, path, config);

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    yield delay(10);
    document.body.removeChild(link);
}