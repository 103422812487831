import {Menu} from "@material-ui/core";
import {MenuProps} from "@material-ui/core/Menu/Menu";


export function BottomAnchoredMenu(props: MenuProps & {horizontal?: 'left' | 'center' | 'right'}) {
    return <Menu
        {...props}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: props.horizontal || 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: props.horizontal || 'center' }}
        />
}

export default BottomAnchoredMenu;