import React from 'react';
import {Paper, Toolbar, Typography, AppBar} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        height: '100vh',
        overflowY: 'auto',
        background: '#FFFFFFCC'
    },
    appBarSpacer: theme.mixins.toolbar,
});

const RawToolbarAndContent = ({children, classes}) => {
    return (
        <React.Fragment>
            <AppBar position={"fixed"} className={classes.appBar}>
                <Toolbar>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit">
                        Skupomat
                    </Typography>
                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Paper elevation={2} style={{padding: '1em'}}>
                    {children}
                </Paper>
            </main>
        </React.Fragment>
    );
};

export const ToolbarAndContent = withStyles(styles)(RawToolbarAndContent);

export default ToolbarAndContent;