import React from 'react';
import {CircularProgress} from "@material-ui/core";
import ToolbarAndContent from './ToolbarAndContent';

export const LoadingScreen = ({message}) => {
    return (
       <ToolbarAndContent>
           <div style={{textAlign: 'center'}}>
               <CircularProgress/>
           </div>
           <p data-test-key="message" style={{textAlign: 'center'}}>{message}</p>
       </ToolbarAndContent>
    );
};

export default LoadingScreen;