export const CHECKING = 'CHECKING';
export const LOGGED_IN = 'LOGGED_IN';
export const NOT_LOGGED_IN = 'NOT_LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';

export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';
export const setAuthStatus = (statusToSet) => ({
    type: SET_AUTH_STATUS,
    authStatus: statusToSet
});

