import {call, put, select} from "redux-saga/effects"
import {resetCurrentTenantWindow} from "../Actions/resetCurrentTenantWindow";
import {busyCall} from "./businessSaga";
import axios from "../../exios";
import {getAxiosConfig, handleValidation} from "./tenantsSaga";
import {resetCurrentProviderWindow} from "../Actions/resetCurrentProviderWindow";

export const getApiUrl = (path, tenantId) => {
    let result = process.env.REACT_APP_API_URL;
    if (tenantId) {
        result += `/tenants/${tenantId}`;
    }
    result += path;
    return result;
};

export const getProviderUrl = (path, tenantId, providerId) => {
    return getApiUrl(`/providers/${providerId}${path}` , tenantId);
}

export const getPurchaserUrl = (path, tenantId, purchaserId) => {
    return getApiUrl(`/purchasers/${purchaserId}${path}` , tenantId);
}

function getConfig (tenantId) {
    return getAxiosConfig(tenantId, 'https://api.skupomat.pl');
}

export function* addTenantEntity(tenantId, savedData, formName, url, successActionFactory) {
    const oldForm = yield select(o => o.currentWindow[formName]);
    yield put(resetCurrentTenantWindow(tenantId, {[formName]: {...oldForm, saving: true}}));
    const config = yield getConfig(tenantId);
    const response = yield busyCall(axios.post, getApiUrl(url, tenantId), savedData, config);

    const {errors, data} = yield call(handleValidation, response);
    if (!!errors) {
        const formWithErrors = {
            ...oldForm,
            errors
        };
        yield put(resetCurrentTenantWindow(tenantId, {[formName]: formWithErrors}));
    } else {
        yield put(successActionFactory(data));
    }
}

export function* updateTenantEntity(tenantId, savedData, formName, url, successActionFactory) {
    const oldForm = yield select(o => o.currentWindow[formName]);
    yield put(resetCurrentTenantWindow(tenantId, {[formName]: {...oldForm, saving: true}}))

    const config = yield getConfig(tenantId);
    const response = yield busyCall(axios.put, getApiUrl(url, tenantId), savedData, config);
    const { errors } = yield call(handleValidation, response);

    if (errors) {
        const formWithErrors = {
            ...oldForm,
            errors
        }
        yield put(resetCurrentTenantWindow(tenantId, {[formName]: formWithErrors}))
    } else {
        yield put(successActionFactory());
    }
}

export function* addProviderEntity(tenantId, providerId, savedData, formName, url, successActionFactory) {
    const oldForm = yield select(o => o.currentWindow[formName]);
    yield put(resetCurrentProviderWindow(tenantId, providerId, { [formName]: { ...oldForm, saving: true }}));

    const config = yield getConfig(tenantId);
    const response = yield call(axios.post, getProviderUrl(url, tenantId, providerId), savedData, config);
    const {errors, data} = yield call(handleValidation, response);

    if (!!errors) {
        const newForm = {
            ...oldForm,
            errors
        };
        yield put(resetCurrentProviderWindow(tenantId, providerId, { [formName]: newForm }));
    } else {
        yield put(successActionFactory(data));
    }
}

export function* updateProviderEntity(tenantId, providerId, savedData, formName, url, successActionFactory) {
    const oldForm = yield select(o => o.currentWindow[formName]);
    yield put(resetCurrentProviderWindow(tenantId, providerId, {[formName]: {...oldForm, saving: true}}));

    const config = yield getConfig(tenantId);
    const response = yield busyCall(axios.put, getProviderUrl(url, tenantId, providerId), savedData, config);
    const {errors} = yield call(handleValidation, response);

    if (!!errors) {
        const formWithErrors = {
            ...oldForm,
            errors
        };
        yield put(resetCurrentProviderWindow(tenantId, providerId, {[formName]: formWithErrors}));
    } else {
        yield put(successActionFactory());
    }
}