import {SET_AUTH_STATUS, CHECKING} from '../Actions/setAuthStatus';
import {RESET_CURRENT_WINDOW} from '../Actions/resetCurrentWindow';
import {SET_ACCESS_TOKEN} from '../Actions/setAccessToken';
import {SET_TENANT_ACCESS_TOKEN} from "../Actions/setTeanantAccessToken";
import {GET_BUSY} from "../Actions/getBusy";
import {GET_REST} from "../Actions/getRest";
import {CLEAR_TENANT_ACCESS_TOKENS} from "../Actions/clearTenantAccessTokens";
import {CLEAR_ACCESS_TOKENS} from "../Actions/clearAccessTokens";
import {ACCEPT_PRIVACY_POLICY} from "../Actions/acceptPrivacyPolicy";
import {SET_CONNECTIVITY_STATUS} from "../Actions/setConnectivityStatus";
import {ADD_NOTIFICATION} from "../Actions/addNotification";
import {REMOVE_NOTIFICATION} from "../Actions/removeNotification";
import {ADD_CACHE_ENTRY} from "../Actions/addCacheEntry";
import {RESET_CACHE} from "../Actions/resetCache";

const initialState = {
    business: 0,
    accessTokens: {},
    authStatus: CHECKING,
    cache: {},
    currentWindow: {},
    tenantsAccessTokens: {},
    privacyPolicyAccepted: false,
    notifications: []
};

const setAuthStatus = (action) => ({authStatus: action.authStatus});
const resetCurrentWindow = (action) => ({currentWindow: action.currentWindow});
const setAccessToken = (action, state) => ({accessTokens: {...state.accessTokens, [action.tokenType]: action.token}});
const clearAccessTokens = () => ({accessTokens: {}});
const setTenantAccessToken = (action, state) =>({
    tenantsAccessTokens: {
        ...state.tenantsAccessTokens,
        [action.tenantId]: {
            ...state.tenantsAccessTokens[action.tenantId],
            [action.audience]: action.accessToken
        }
    }
});
const clearTenantAccessTokens = () => ({tenantsAccessTokens:{}});

const getBusy = (action, state) => ({business: state.business + 1});
const getRest = (action, state) => ({business: state.business - 1});

const acceptPrivacyPolicy = () => ({privacyPolicyAccepted: true});

const setConnectivityStatus = (action) => ({connectivityStatus: action.status});

const addNotification = (action, state) => {
    const oldNotifications = state.notifications;

    const newNotifications = [
        ...oldNotifications,
        {
            id: action.id,
            message: action.message
        }
    ]

    return {
        ...state,
        notifications: newNotifications
    }
};

const removeNotification = (action, state) => {
    const oldNotifications = state.notifications;

    const newNotifications = oldNotifications.filter(o => o.id !== action.id);

    return {
        ...state,
        notifications: newNotifications
    }
}

const handleAddCacheEntry = (action, state) => {
    const {tenantId, key, etag, data, url} = action;
    const cache = {...state.cache};
    cache[tenantId] = {...cache[tenantId] };
    cache[tenantId][key] = {
        data,
        etag,
        url
    };
    return {cache};

}

export const getInitialState = () => initialState;

export const rootReducer = (state, action) => {
    if (state === undefined) {
        state = initialState;
    }
    const map = {
        [RESET_CURRENT_WINDOW]: resetCurrentWindow,
        [SET_ACCESS_TOKEN]: setAccessToken,
        [CLEAR_ACCESS_TOKENS]: clearAccessTokens,
        [SET_AUTH_STATUS]: setAuthStatus,
        [SET_TENANT_ACCESS_TOKEN]: setTenantAccessToken,
        [CLEAR_TENANT_ACCESS_TOKENS]: clearTenantAccessTokens,
        [GET_BUSY]: getBusy,
        [GET_REST]: getRest,
        [ACCEPT_PRIVACY_POLICY]: acceptPrivacyPolicy,
        [SET_CONNECTIVITY_STATUS]: setConnectivityStatus,
        [ADD_NOTIFICATION]: addNotification,
        [REMOVE_NOTIFICATION]: removeNotification,
        [ADD_CACHE_ENTRY]: handleAddCacheEntry,
        [RESET_CACHE]: () => ({cache: {}})
    };

    const handler = map[action.type];
    if (handler){
        let result = map[action.type](action, state);
        result = {...state, ...result};
        return result;
    }
    return state;
};

export default rootReducer;