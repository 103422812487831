import moment from "moment";
import numeral from "numeral";

export function date(date) {
    return moment(date).format('L');
}

export function jsonDate(date) {
    return moment(date).format("YYYY-MM-DDTHH:mm:ss");
}

export function money(sum) {
    return numeral(sum).format("#,##0.00");
}

export function amount(o) {
    return numeral(o).format("#,##0.00");
}

export function percent(o) {
    return numeral(o).format("0%");
}

const defaultExport = {
    date,
    jsonDate,
    money,
    amount,
    percent
};
export default defaultExport;