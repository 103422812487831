import React, {useRef, useState} from 'react';
import {AppBar, MenuItem, Toolbar, Typography, makeStyles, Button, Hidden, IconButton} from "@material-ui/core";
import RegisterForm from "./RegisterForm";
import LoginForm from "./LoginForm";
import Link from '../ViewComponents/Link';
import ResetPasswordForm from "./ResetPasswordForm";
import {Menu as MenuIcon} from "@material-ui/icons";
import PrivacyPolicy from "./PrivacyPolicy";
import BottomAnchoredMenu from "../ViewComponents/BottomAnchoredMenu";

const styles = theme => ({
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        height: '100vh',
        overflowY: 'auto',
        background: '#FFFFFFCC'
    },
    button: {
        marginLeft: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    appBarSpacer: theme.mixins.toolbar,
});

const useStyles = makeStyles(styles);

export function LoggedOutDashboard() {
    const classes = useStyles();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const iconButtonRef = useRef();

    const handleClose = () => {
      setMenuOpen(false);
    }

    return <React.Fragment>
        <AppBar position={"fixed"} className={classes.appBar}>
            <Toolbar>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit">
                    Skupomat
                </Typography>
                <div className={classes.spacer}/>
                <Hidden smDown>
                    <Button color="inherit" href="/login" component={Link} className={classes.button}>Zaloguj się</Button>
                    <Button color="inherit" href="/" component={Link} variant="outlined" className={classes.button}>Zarejestruj się</Button>
                </Hidden>
                <Hidden mdUp>
                    <IconButton
                        edge="start"
                        onClick={() => setMenuOpen(true)}
                        ref={iconButtonRef}
                        color="inherit">
                        <MenuIcon/>
                    </IconButton>
                    <BottomAnchoredMenu
                        anchorEl={iconButtonRef.current}
                        open={isMenuOpen}
                        onClose={handleClose}>
                        <MenuItem href="/login" onClick={handleClose} component={Link}>Zaloguj się</MenuItem>
                        <MenuItem href="/" onClick={handleClose} component={Link}>Zarejestruj się</MenuItem>
                    </BottomAnchoredMenu>
                </Hidden>
            </Toolbar>
        </AppBar>
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <RegisterForm/>
            <LoginForm/>
            <ResetPasswordForm/>
        </main>
        <PrivacyPolicy/>
    </React.Fragment>
}
export default LoggedOutDashboard;