import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    makeStyles,
    Link
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    policyList: {
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(3)
    }
}))

export function PrivacyPolicy({open, onAccept}) {
    const classes = useStyles();
    return <Dialog open={open} fullWidth maxWidth="lg">
        <DialogTitle>Polityka prywatności</DialogTitle>
        <DialogContent>
            <Typography variant="body2">Dzień Dobry!</Typography>
            <Typography variant="body2">Na wstępie tego dokumentu pragniemy zaznaczyć, iż wszelkie dane gromadzone przez
                serwis skupomat.pl mają na celu lepsze dopasowanie naszych usług do potrzeb użytkowników, a w
                szczególności klientów. Niniejszy dokument zawiera wykaz wszystkich narzędzi dostarczających danych,
                bądź przy pomocy których dane są zarządzane. Zastrzegamy sobie prawo do zmiany listy narzędzi, z których
                usług korzystamy, wszelkie zmiany zostaną uwzględnione w tym dokumencie, a w przypadkach gdy zmiany
                dotyczą danych osobowych, osoby na które zmiana ma wpływ zostaną powiadomione przy pomocy poczty
                elektronicznej.</Typography>

            <Typography variant="h5">Administrator danych</Typography>
            <Typography variant="body2">Administratorem danych osobowych jest Wojciech Gomoła, prowadzący działalność
                gospodarczą o nazwie: Skupomat, z siedzibą pod adresem: Krępa 123, 21-146
                Jeziorzany, o numerach NIP: 7141934228 i REGON: 061704831. </Typography>
            <Typography variant="body2">W przypadku pytań związanych z polityką prywatności proszę o kontakt pod adresem
                email: wojciech.gomola@skupomat.pl.</Typography>

            <Typography variant="h5">Uprawnienia użytkownika</Typography>
            <Typography variant="body2">RODO przyznaje Ci następujące potencjalne uprawnienia związane z przetwarzaniem
                Twoich danych osobowych:</Typography>
            <ul className={classes.policyList}>
                <li>prawo dostępu do danych osobowych</li>
                <li>prawo do sprostowania danych osobowych</li>
                <li>prawo do usunięcia danych osobowych</li>
                <li>prawo do ograniczenia przetwarzania danych osobowych</li>
                <li>prawo do wniesienia sprzeciwu co do przetwarzania danych osobowych</li>
                <li>prawo do przenoszenia danych</li>
                <li>prawo do wniesienia skargi do organu nadzorczego</li>
                <li>prawo do odwołania zgody na przetwarzanie danych osobowych, jeżeli takową zgodę wyraziłeś</li>
            </ul>

            <Typography variant="body2">Zasady związane z realizacją wskazanych uprawnień zostały opisane szczegółowo w
                art. 16 – 21 RODO. Zachęcamy do zapoznania się z tymi przepisami. Ze swojej strony uważamy za potrzebne
                wyjaśnić Ci, że wskazane powyżej uprawnienia nie są bezwzględne i nie będą przysługiwać Ci w stosunku do
                wszystkich czynności przetwarzania Twoich danych osobowych. Dla Twojej wygody dołożyliśmy starań, by w
                ramach opisu poszczególnych operacji przetwarzania danych osobowych wskazać na przysługujące Ci w ramach
                tych operacji uprawnienia.</Typography>

            <Typography variant="body2">Podkreślamy, że jedno z uprawnień wskazanych powyżej przysługuje Ci zawsze –
                jeżeli uznasz, że przy przetwarzaniu Twoich danych osobowych dopuściliśmy się naruszenia przepisów o
                ochronie danych osobowych, masz możliwość wniesienia skargi do organu nadzorczego (Prezesa Urzędu
                Ochrony Danych Osobowych).</Typography>

            <Typography variant="body2">Zawsze możesz również zwrócić się do nas z żądaniem udostępnienia Ci informacji
                o tym, jakie dane na Twój temat posiadamy oraz w jakich celach je przetwarzamy. Wystarczy, że wyślesz
                wiadomość na adres wojciech.gomola@skupomat.pl. Dołożyliśmy jednak wszelkich starań, by interesujące Cię
                informacje zostały wyczerpująco przedstawione w niniejszej polityce prywatności. Podany powyżej adres
                e-mail możesz wykorzystać również w razie jakichkolwiek pytań związanych z przetwarzaniem Twoich danych
                osobowych.</Typography>

            <Typography variant="h5">Rejestracja ruchu</Typography>

            <Typography variant="body2">Serwis Skupomat.pl wykorzystuje technologie ciasteczek (coockies) oraz korzysta
                także z narzędzi innych podmiotów tą technologię wykorzystujących.</Typography>

            <Typography variant="body2">Cookies to niewielkie informacje tekstowe, przechowywane na Twoim urządzeniu
                końcowym (np. komputerze, tablecie, smartfonie), które w ograniczonym stopniu mogą być zapisywane i
                odczytywane przez strony, które odwiedzasz.</Typography>

            <Typography variant="h5">Wykorzystywane narzędzia - Wykaz powierzeń</Typography>

            <Typography variant="body2">Serwis wykorzystuje następujące narzędzia podmiotów trzecich, które mogą
                gromadzić dane osobowe i/lub o aktywności użytkownika:</Typography>

            <ul className={classes.policyList}>
                <li>Google Cloud - Aplikacja wykorzystuje infrastrukturę firmy Google. Są na niej przechowywane dane
                    wprowadzane w aplikacji.W szczególności dane dostawców, odbiorców, wystawione dokumenty. Dodatkowo
                    firma ta gromadzi dane techniczne, w szczególności godziny i formy komunikacji z ich infrastrukturą.
                    Firma Google jest reprezentowana w Polsce przez: Google Cloud Poland Sp. z o.o. NIP: 5252822767, z
                    siedzibą ul. Emilii Plater 53, 00-113 Warszawa. Polityka prywatności jest dostępna pod
                    adresesem: <Link
                        href="https://cloud.google.com/terms/cloud-privacy-notice">https://cloud.google.com/terms/cloud-privacy-notice</Link>
                </li>
                <li>Auth0 - Aplikacja wykorzystuje usługę Auth0 dostarczaną przez firmę Auth0 Inc. do kontroli dostępu
                    (min. do weryfikacji hasła). Siedziba firmy znajduje się pod adresem: 10800 NE 8th Street,
                    Suite 700, Bellevue, WA 98004, USA. Polityka prywatności jest dostępna pod adresem: <Link
                        href="https://auth0.com/privacy">https://auth0.com/privacy</Link>
                </li>
                <li>OVH - Aplikacja wykorzystuje infrastruturę firmy OVH, która gromadzi dane techniczne w szczególności
                    godziny i formy komunikacji z ich infrastrukturą. Firma OVH jest reprezentowana w Polsce przez: OVH
                    Sp.z.o.o nr KRS 0000220286, NIP: 899-25-20-556, z siedzibą ul. Swobodna 1, 50-088 Wrocław. Polityka
                    prywatności jest dostępna pod adresem: <Link
                        href="https://us.ovhcloud.com/legal/privacy-policy">https://us.ovhcloud.com/legal/privacy-policy</Link>
                </li>
                <li>Google Analytics – analiza odwiedzin serwisu – w szczególności popularności poszczególnych artykułów
                    i stron, źródeł ruchu: czy użytkownik znalazł artykuł w wyszukiwarce, wpisie na fb, newsletterze czy
                    wszedł bezpośrednio. Narzędzie rozwijane jest przez firmę Google LLC, z siedzibą 1600 Amphitheatre
                    Parkway, Mountain View, California, Stany zjednoczone. Sposób wykorystywania danych zbieranych przez
                    to narzędzie jest opisany w <Link href="https://www.google.com/analytics/terms/pl.html">Warunkach
                        korzytania z usługi</Link>.
                </li>
                <li>
                    Sendgrid - Aplikacja wykorzystuje usługę Sendgrid dostarczaną przez firmę Twilio Inc. do wysyłania
                    wiadości email. Siedziba firmy znajduje się pod adresem: 375 Beale Street, Suite 300, San Francisco,
                    Stany Zjednoczone. Polityka prywatności jest dostępna pod adresem:
                    <Link href="https://www.twilio.com/legal/privacy">https://www.twilio.com/legal/privacy</Link>
                </li>
            </ul>

        </DialogContent>
        <DialogActions>
            <Button onClick={onAccept} variant="contained" color="primary">Zaakceptuj</Button>
        </DialogActions>
    </Dialog>
}

export default PrivacyPolicy;