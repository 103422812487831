import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {acceptPrivacyPolicy} from "../State/Actions/acceptPrivacyPolicy";
import Raw from '../ViewComponents/PrivacyPolicy';

export function PrivacyPolicy() {
    const dispatch = useDispatch();
    const privacyPolicyAccepted = useSelector(o => o.privacyPolicyAccepted);

    return <Raw open={!privacyPolicyAccepted} onAccept={() => dispatch(acceptPrivacyPolicy())} />
}

export default PrivacyPolicy;