import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardContent, CardHeader, CardActions,
    Grid,
    Link,
    TextField,
    Typography, Snackbar, CircularProgress
} from "@material-ui/core";
import RouteLink from './Link';
import {Alert} from "@material-ui/lab";
import ErrorsPanel from "./ErrorsPanel";

export function LoginForm({message, errors, checking, onSubmit}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSnackOpen, setSnackOpen] = useState(!!message);

    useEffect(() => {
        if (message) {
            setSnackOpen(true);
        }
    }, [message]);

    const canSubmit = !checking && !!email && !!password;

    const onSubmitLocal = e => {
        e.preventDefault();

        if (!canSubmit) {
            return;
        }

        onSubmit({
            email,
            password
        });
    };

    return <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} md={6} lg={4}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={isSnackOpen}
                autoHideDuration={6000}
                onClose={() => setSnackOpen(false)}>
                <Alert severity="success" onClose={() => setSnackOpen(false)}>{message}</Alert>
            </Snackbar>
            <form onSubmit={onSubmitLocal}>
                <Card>
                    <CardHeader title="Witaj ponownie!"/>
                    <CardContent>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="body2" align="center">Dbamy o bezpieczeństwo twoich danych. Podaj, tym razem, adres email i hasło.</Typography>
                            </Grid>
                            <Grid item>
                                <ErrorsPanel errors={errors}/>
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    label="Adres email"
                                    type="email"
                                    disabled={checking}
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}/>
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    label="Hasło"
                                    type="password"
                                    disabled={checking}
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}/>
                            </Grid>
                            <Grid item style={{textAlign: "right"}}>
                                <Typography variant="caption">
                                    Nie pamiętasz hasła? <Link href="/reset-password" component={RouteLink}>Utwórz nowe</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={checking && <CircularProgress color="inherit" size={14}/>}
                            fullWidth>
                            Zaloguj się
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </Grid>
    </Grid>;
}

export default LoginForm;
